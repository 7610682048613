import { observer } from "mobx-react-lite";

type ConfigMappingKey = "subHeader" | "bodyText" | "header";

const CONFIG_MAPPING: Record<
  ConfigMappingKey,
  { fontSize: number; text: string; fontFamilyKey: "HEADER" | "BODY" }
> = {
  subHeader: {
    fontSize: 44,
    text: "Sub header",
    fontFamilyKey: "HEADER" as const,
  },
  bodyText: {
    fontSize: 30,
    text: "Body text",
    fontFamilyKey: "BODY" as const,
  },
  header: {
    fontSize: 76,
    text: "Header",
    fontFamilyKey: "HEADER" as const,
  },
};

const CustomTextPanel: ObserverComponent = observer(({ store }) => {
  const addElement = (type: ConfigMappingKey) => {
    let color, fontFamilyMap;

    if (store.activePage) {
      color = store.activePage.children.reduce((acc, child) => {
        if (child?.custom?.colorType === "TEXT") {
          return child.fill;
        }

        return acc;
      }, "");

      // TODO: This needs better typings
      fontFamilyMap = store.activePage.children.reduce((acc, child) => {
        if (child?.custom?.type) {
          acc[child?.custom?.type] = child.fontFamily;
        }

        return acc;
      }, {});
    }

    const { fontFamilyKey, ...config } = CONFIG_MAPPING[type];

    store.activePage.addElement({
      x: store.activePage.computedWidth / 2 - 150,
      y: store.activePage.computedWidth / 2 - 40,
      type: "text",
      fill: color,
      width: 300,
      fontFamily: fontFamilyMap[fontFamilyKey],
      ...config,
    });
  };

  return (
    <div className="flex flex-col gap-6 items-center py-4 [&_*]:cursor-pointer">
      <h2 className="text-3xl" onClick={() => addElement("header")}>
        Create header
      </h2>
      <h4 className="text-xl" onClick={() => addElement("subHeader")}>
        Create sub header
      </h4>
      <p onClick={() => addElement("bodyText")}>Create body text</p>
    </div>
  );
});

export default CustomTextPanel;
