import { Button, message, notification } from "antd";
import {
  AuthCredentials,
  Integration as ChaliceIntegration,
} from "api/config/chalice-api";
import {
  FacebookPagesWithInstagramAccount,
  fetchSocialAccountDetails,
  SocialAccountDetails,
} from "api/integrations";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { genericError } from "fixtures/globalConstants";
import { InstagramIntegrationType } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { addIntegration } from "store/user/userActions";
import { Integration, IntegrationType } from "store/user/userConstants";
import {
  currentBusinessGetter,
  integrationsGetter,
} from "store/user/userSlice";
import { formatSocialName } from "utils/generalUtils";

const useInstagramConnection = (
  setLoadingItem: (item: IntegrationType | null) => void
) => {
  const instagramStoriesEnabled = useTypedFeatureIsOn("instagram-stories");

  const currentBusiness = useAppSelector(currentBusinessGetter);

  const { socials_per_business: maxSocialConnections = null } =
    currentBusiness.owner?.subscription?.restrictions ?? {};

  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const connectedSocials = useAppSelector(integrationsGetter);

  const [, setSearchParams] = useSearchParams();

  const clearAllParams = () => setSearchParams({}, { replace: true });

  const connectExistingAccount = async (
    integrationType: IntegrationType,
    existingIntegration: Integration | ChaliceIntegration
  ) => {
    api.destroy();
    setLoadingItem(integrationType);
    try {
      await dispatch(
        addIntegration({
          auth_credentials: existingIntegration.auth_credentials,
          profile_picture: existingIntegration.profile_picture,
          profile_user_id: existingIntegration.profile_user_id,
          username: existingIntegration.username,
          type: integrationType,
        })
      );
      message.success(
        `${formatSocialName(integrationType)} connected successfully.`
      );
    } catch (error) {
      genericError(error);
    } finally {
      setLoadingItem(null);
      clearAllParams();
    }
  };

  const handleInstagramConnection = async (
    integrationType: IntegrationType,
    onShowNote: () => void
  ) => {
    const existingInstagramIntegration = connectedSocials.find(({ type }) =>
      ["instagram", "instagramStory"].includes(type)
    );

    if (integrationType !== "facebook" && existingInstagramIntegration) {
      const existingIntegrationName = formatSocialName(
        existingInstagramIntegration.type
      );
      api.open({
        message: `Use Existing ${existingIntegrationName} Connection?`,
        description: `You've already connected ${existingIntegrationName}. Would you like to use the same account for ${formatSocialName(integrationType)}?`,
        btn: (
          <div
            className="flex gap-2 w-full justify-end"
            onClick={() => api.destroy()}
          >
            <Button
              type="primary"
              size="small"
              onClick={() =>
                connectExistingAccount(
                  integrationType,
                  existingInstagramIntegration
                )
              }
            >
              Use Existing
            </Button>
            <Button size="small" onClick={onShowNote}>
              Connect New
            </Button>
          </div>
        ),
      });
    } else {
      onShowNote();
    }
  };

  const addInstagramAccount = async ({
    integrationCredentials,
    resetCredentials,
    integrationType,
    setIsLoading,
    closeModal,
    account,
  }: {
    integrationCredentials: AuthCredentials | null;
    setIsLoading: (loading: boolean) => void;
    integrationType: IntegrationType;
    account: SocialAccountDetails;
    resetCredentials: () => void;
    closeModal: () => void;
  }) => {
    setIsLoading(true);

    if (!integrationCredentials) {
      throw new Error("No integration credentials found.");
    }

    try {
      const createdIntegration = await dispatch(
        addIntegration({
          auth_credentials: integrationCredentials,
          type: integrationType,
          ...account,
          profile_picture: account?.picture_url,
          profile_user_id: account?.profile_id,
        })
      ).unwrap();
      if (!createdIntegration) {
        message.error("Unable to add integration, please try again.");
        return;
      }

      close();

      const newIntegrationType =
        integrationType === "instagramStory" ? "instagram" : "instagramStory";

      const integrationName = formatSocialName(integrationType);
      const newIntegrationName = formatSocialName(newIntegrationType);

      if (
        connectedSocials.some(({ type }) => type === newIntegrationType) ||
        (maxSocialConnections !== null &&
          connectedSocials.length >= maxSocialConnections) ||
        (newIntegrationType === "instagramStory" && !instagramStoriesEnabled)
      ) {
        message.success(`${integrationName} connected successfully.`);
        clearAllParams();
      } else {
        const connectText = `Connect ${newIntegrationName}?`;
        const descriptionText = `You've successfully connected ${integrationName}! Would you like to connect ${newIntegrationName} as well?`;

        api.success({
          message: connectText,
          description: descriptionText,
          showProgress: true,
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() =>
                connectExistingAccount(newIntegrationType, createdIntegration)
              }
            >
              Connect
            </Button>
          ),
        });
      }
    } catch (error) {
      genericError(error);
    } finally {
      closeModal();
      setIsLoading(false);
      resetCredentials();
    }
  };

  const handleInstagramAuthSuccess = async ({
    setInstagramAccounts,
    integrationType,
    token,
  }: {
    setInstagramAccounts: (accounts: FacebookPagesWithInstagramAccount) => void;
    integrationType: InstagramIntegrationType;
    token: string;
  }) => {
    try {
      const igAccounts = await fetchSocialAccountDetails({
        type: integrationType,
        token,
      });
      setInstagramAccounts(igAccounts);
    } catch (error) {
      message.error("Error connecting your Instagram");
      clearAllParams();
    } finally {
      setLoadingItem(null);
    }
  };

  return {
    handleInstagramAuthSuccess,
    handleInstagramConnection,
    addInstagramAccount,
    contextHolder,
  };
};

export default useInstagramConnection;
