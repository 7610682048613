import { Button, Form, Input, message } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
import { SocialLinks } from "api/config/chalice-api";
import { omniboxRequest, signup } from "api/user";
import DescriptionStrengthIndicator from "components/Common/DescriptionStrengthIndicator";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import LogoLink from "config/theme/LogoLink";
import { PageLoader } from "designSystem/Loader";
import { genericError } from "fixtures/globalConstants";
import { validateUrl } from "hooks/useOmnibox";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo, setUserInfo } from "store/user/userSlice";
import { ScrapedBusinessData } from "../businessOnboardingConstants";
import OnboardingModalTemplate, {
  OnboardingModalTemplateProps,
} from "./OnboardingModalTemplate";
import OnboardingModalTitle from "./OnboardingModalTitle";

const URL_PARAM = "url";

const POSSIBLE_SOCIALS = [
  "twitter",
  "instagram",
  "linkedin",
  "facebook",
] as const;

type BusinessSummaryProps = {
  saveScrapedData: (data: ScrapedBusinessData) => void;
  onClose: () => void;
  newUser?: boolean;
};

type BusinessSummaryForm = {
  website: string;
  customDescription: string;
};

type FillInModeType = "website" | "customDescription";

const BusinessSummaryInput = ({
  saveScrapedData,
  onClose,
  newUser,
}: BusinessSummaryProps) => {
  const disableImageAutoScrape = useTypedFeatureIsOn(
    "onboarding-disable-image-scraping"
  );

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { isAnonymous } = useAppSelector(selectUserInfo);
  const { closeGlobalModal } = useGlobalModal();

  const { realm } = useAppContext();

  const [fillInMode, setFillInMode] = useState<FillInModeType>(
    (searchParams.get("inputMode") as FillInModeType) ?? "website"
  );

  const [businessSummary] = useForm<BusinessSummaryForm>();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(!isAnonymous);

  const customDescription =
    useWatch("customDescription", businessSummary) ?? "";

  const initialized = useRef(false);
  useEffect(() => {
    !initialized.current && verifyParams();

    // Avoid running twice in dev mode
    initialized.current = true;
  }, [isAnonymous]);

  const verifyUser = async () => {
    if (isAnonymous && !user.token) {
      try {
        const response = await signup();
        dispatch(setUserInfo(response));
      } catch {
        message.error(
          "Unable to proceed, please try creating an account first",
          5000
        );
        closeGlobalModal();
        navigate("/login", { replace: true });
        return false;
      }
    }

    return true;
  };

  const verifyParams = async () => {
    const urlParam = searchParams.get(URL_PARAM);
    if (urlParam) {
      if (validateUrl(urlParam)) {
        setShowModal(false);
        submitForm({ website: urlParam });
        searchParams.delete(URL_PARAM);
        setSearchParams(searchParams);
      } else {
        setShowModal(true);
        businessSummary.setFieldValue("website", urlParam);
        message.error("Please enter a valid website url.");
      }
    } else {
      setShowModal(true);
    }
  };

  const submitForm = async (data: Partial<BusinessSummaryForm>) => {
    setLoading(true);
    const verified = await verifyUser();
    if (!verified) {
      return;
    }

    try {
      if (fillInMode === "website" && data.website) {
        const {
          content,
          logo_url: logoUrl,
          palette,
          social_links: socials,
          image_urls: imageUrls,
        } = await omniboxRequest({ url: data.website, crawl: true });

        const scrapedImages = disableImageAutoScrape ? undefined : imageUrls;

        const socialHandle = socials
          ? getPreferredUsername(socials)
          : undefined;
        saveScrapedData({
          content,
          logoUrl,
          palette,
          socialHandle,
          website: data.website,
          scrapedImages,
        });
      } else if (data.customDescription) {
        saveScrapedData({ content: data.customDescription });
      }
    } catch (error) {
      genericError(error);
      setShowModal(true);
    }

    setLoading(false);
  };

  const getPreferredUsername = (socialMediaLinks: SocialLinks) => {
    for (const social of POSSIBLE_SOCIALS) {
      const match = socialMediaLinks[social]?.match(
        /(?:facebook\.com|instagram\.com|twitter\.com\/|linkedin\.com\/in\/)(\w+)/
      );
      if (match) {
        return match[1];
      }
    }
  };

  const handleCancel = () => onClose();

  return showModal ? (
    <SummaryContentWrapper
      open
      header={
        newUser
          ? `Welcome to ${realm.isMarky ? "Marky" : realm.name ?? "Agency"}!`
          : "Create a Business"
      }
      subHeader="Let's start by creating your own personalized business profile. This will be used to generate unique custom content for your brand."
      newUser={newUser}
      closable={!newUser && !loading}
      maskClosable={!newUser && !loading}
      onClose={handleCancel}
      onCancel={handleCancel}
      footer={
        <div className="space-y-3">
          <div className="flex gap-2">
            {!newUser && <Button onClick={handleCancel}>Cancel</Button>}
            <Button
              form="summaryForm"
              type="primary"
              className="flex-grow"
              htmlType="submit"
              loading={loading}
            >
              Generate Business Profile
            </Button>
          </div>
          {newUser && (
            <p className="text-antd-colorTextDescription text-sm text-center">
              Already have an account?{" "}
              <Link
                onClick={() => {
                  onClose();
                  navigate({ pathname: "/logout", search: "redirectTo=login" });
                }}
              >
                Login
              </Link>
            </p>
          )}
        </div>
      }
    >
      <Form
        id="summaryForm"
        layout="vertical"
        disabled={loading}
        onFinish={submitForm}
        form={businessSummary}
        initialValues={{ website: "", customDescription: "" }}
        requiredMark={false}
        className="[&_h6]:!-mt-4"
      >
        {fillInMode === "website" ? (
          <div className="space-y-2">
            <Form.Item
              name="website"
              label="Business Website"
              validateTrigger="onBlur"
              required
              rules={[
                {
                  min: 1,
                  message: "Please enter a valid website link",
                  validator: (_, value) =>
                    validateUrl(value) ? Promise.resolve() : Promise.reject(),
                },
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>

            <h6>
              Don't have a website? No problem, instead you can{" "}
              <Link
                disabled={loading}
                onClick={() => setFillInMode("customDescription")}
              >
                write a description
              </Link>
            </h6>
          </div>
        ) : (
          <div>
            <Form.Item
              name="customDescription"
              validateTrigger="onBlur"
              className="[&_label]:w-full [&_::after]:!m-0"
              label={
                <div className="w-full flex justify-between gap-6">
                  <span>Tell us about your business</span>
                  <DescriptionStrengthIndicator text={customDescription} />
                </div>
              }
              required
              rules={[
                {
                  required: true,
                  min: 100,
                  message: "Description should be at least 100 characters long",
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Type a few sentences on your business. Marky will use this information to create a custom profile for you, so the more details you can provide the better!"
              />
            </Form.Item>
            <h6>
              Have a website? Autofill using your{" "}
              <Link disabled={loading} onClick={() => setFillInMode("website")}>
                website url
              </Link>
            </h6>
          </div>
        )}
      </Form>
    </SummaryContentWrapper>
  ) : (
    <PageLoader />
  );
};

const SummaryContentWrapper = ({
  newUser,
  ...props
}: OnboardingModalTemplateProps & {
  newUser?: boolean;
  footer: ReactNode;
}) =>
  newUser ? (
    <div className="fixed top-0 bg-antd-colorBgLayout z-10 h-full w-full flex items-center justify-center p-10">
      <div className="absolute top-7 left-8 w-[120px]">
        <LogoLink linkToLandingPage />
      </div>
      <div className="max-w-lg space-y-8">
        <OnboardingModalTitle {...props} />
        {props.children}
        {props.footer}
      </div>
    </div>
  ) : (
    <OnboardingModalTemplate {...props} />
  );

export default BusinessSummaryInput;
