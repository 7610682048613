import { chaliceClient } from "api/baseClients";
import LogRocket from "logrocket";
import _refiner from "refiner-js";
import { getFullName } from "store/user/userActions";
import { UserInfo } from "store/user/userConstants";
import { EVENT_MAPPING_DICT, TrackingEventKey } from "./eventMapping";
import { GTM_EVENT_DATA } from "./fixtures/gtmEventsFixture";
import { META_PIXEL_EVENT_DATA } from "./fixtures/metaPixelEventsFixture";
import { TriggerRefinerArgs } from "./fixtures/refinerEventsFixture";
import {
  TIKTOK_EVENT_DATA,
  TTQ_REGISTRATION_CONTENT,
} from "./fixtures/tikTokEventsFixture";
import { TrackingDataArg } from "./trackingDataArgs";

export const trackEvent = async <T extends TrackingEventKey>(
  eventKey: T,
  ...[data]: TrackingDataArg<T>
) => {
  // Existing logic -- only track sign_up events in prod
  if (eventKey === "sign_up" && import.meta.env.VITE_ENV !== "PRODUCTION")
    return;

  const eventMapping = EVENT_MAPPING_DICT[eventKey];
  if (eventMapping.fbqEvent) {
    const attachData = extractDataForService(
      META_PIXEL_EVENT_DATA,
      eventMapping.fbqEvent,
      data
    );

    window.fbq(
      "track",
      eventMapping.fbqEvent,
      attachData as MetaPixelEventData[MetaPixelEvent]
    );
  }

  if (eventMapping.gtmEvent) {
    if (eventMapping.gtmEvent === "userData" && data?.userInfo) {
      logGtmUser(data.userInfo);
    } else {
      const attachData = extractDataForService(
        GTM_EVENT_DATA,
        eventMapping.gtmEvent,
        data
      );

      window.dataLayer.push({
        event: eventMapping.gtmEvent,
        ...attachData,
      });

      LogRocket.track(
        snakeCaseToPascalCase(eventMapping.gtmEvent),
        attachData as Record<string, string>
      );
    }
  }

  if (eventMapping.refinerEvent) {
    triggerRefiner({
      eventName: eventMapping.refinerEvent,
      ...data?.refinerArgs,
    });
  }

  if (eventMapping.ttqEvent) {
    if (!window.ttq.context?.userInfo?.email) {
      await logTtqIdentity();
    }

    const attachData = extractDataForService(
      TIKTOK_EVENT_DATA,
      eventMapping.ttqEvent,
      data
    );

    if (attachData || eventMapping.ttqEvent === "CompleteRegistration") {
      const { content_id, content_name, content_type, ...rest } = {
        ...TTQ_REGISTRATION_CONTENT,
        ...attachData,
      };

      window.ttq.track(eventMapping.ttqEvent, {
        ...rest,
        contents: [{ content_id, content_name, content_type }],
      });
    } else {
      window.ttq.track(eventMapping.ttqEvent);
    }
  }
};

const extractDataForService = <
  T extends TrackingEventKey,
  EventDict extends Record<string, Record<string, unknown>>,
>(
  eventDict: EventDict,
  eventKey: string,
  ...[data]: TrackingDataArg<T>
) => {
  if (data && Object.keys(eventDict).includes(eventKey)) {
    const dataKeys = Object.keys(eventDict[eventKey]);
    return dataKeys.reduce(
      (prev, key) => {
        prev[key] = key in data ? data[key as keyof typeof data] : undefined;

        return prev;
      },
      {} as Record<string, unknown>
    );
  }
};

const snakeCaseToPascalCase = (snakeCase: string) =>
  snakeCase
    .split("_")
    .reduce<string>(
      (prev, cur) => prev + cur[0].toUpperCase() + cur.slice(1),
      ""
    );

/**
 * Trigger a Refiner event. Optionally add attributes to the upcoming survey, or trigger the survey.
 *
 * @param eventName The event to trigger. Send "launch_survey" to skip
 * triggering an event.
 * @param surveyId The survey identifier to add attributes to, or to open.
 * @param addAttributes Additional data to attach to upcoming survey responses
 * when either `eventName` or `surveyId` matches the launched survey.
 * @param triggerSurvey If true, the survey will be triggered immediately.
 */
export const triggerRefiner = ({
  eventName,
  surveyId,
  addAttributes,
  triggerSurvey,
}: TriggerRefinerArgs) => {
  eventName !== "launch_survey" && _refiner("trackEvent", eventName);

  if (surveyId || addAttributes) {
    _refiner(
      "onBeforeShow",
      (
        showSurveyId: string,
        surveyData: { config: { trackedEventName: string } },
        showSurvey: () => void
      ) => {
        if (
          addAttributes &&
          (showSurveyId === surveyId ||
            surveyData.config.trackedEventName === eventName)
        ) {
          _refiner("addToResponse", addAttributes);
          _refiner("onComplete", () => _refiner("addToResponse", null));
        }
        showSurvey();
      }
    );

    triggerSurvey && _refiner("showForm", surveyId, true);
  }
};

/** Save logged in user to Google Tag Manager */
const logGtmUser = (userInfo: UserInfo) =>
  window.dataLayer.push({
    event: EVENT_MAPPING_DICT.user_data.gtmEvent,
    userId: userInfo.id,
    userEmail: userInfo.email,
    userName: getFullName(userInfo),
    userSubscriptionPlan: userInfo.subscription?.plan,
    userIsDev: import.meta.env.VITE_ENV === "DEV",
  });

/** Save new user to TikTok Pixel */
const logTtqIdentity = async () => {
  try {
    const hashedDetails = await chaliceClient.get<{
      hashedEmail: string;
      hashedPhone: string;
    }>("/stripe/hashed-details");

    if (hashedDetails && window.ttq) {
      window.ttq.identify({
        email: hashedDetails.hashedEmail,
        phone_number: "",
      });
    } else {
      console.error("No data returned when fetching hashed details");
    }
  } catch (error) {
    console.error("Error fetching hashed details:", error);
  }
};
