import { Button } from "antd";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { useMemo } from "react";
import { GoRocket } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "store";
import { setShowOnboardingTour } from "store/user/userSlice";

const ProductTourButton = () => {
  const onboardingTourEnabled = useTypedFeatureIsOn("onboarding-tour");

  const dispatch = useAppDispatch();
  const { userInfo, isAgencyMember, showOnboardingTour } = useAppSelector(
    (state) => state.user
  );

  const isTourButtonVisible = useMemo(
    () =>
      onboardingTourEnabled &&
      userInfo.subscription?.plan === "free" &&
      !showOnboardingTour &&
      !isAgencyMember,
    [isAgencyMember, showOnboardingTour, userInfo.subscription?.plan]
  );

  return (
    isTourButtonVisible && (
      <Button
        className="max-lg:hidden"
        onClick={() => dispatch(setShowOnboardingTour("force"))}
        icon={<GoRocket />}
      >
        Product Tour
      </Button>
    )
  );
};

export default ProductTourButton;
