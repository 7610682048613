import { AppFeatures } from "config/Growthbook/feature-flags";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import {
  ANALYTICS_ITEM_ID,
  BRANDING_MENU_ITEM_ID,
  BUSINESS_PROFILE_MENU_ITEM_ID,
  CALENDAR_PAGE_NAV_ID,
  CLIENTS_MENU_ITEM_ID,
  POST_MENU_ITEM_ID,
  SOCIALS_MENU_ITEM_ID,
  TEAMS_MENU_ITEM_ID,
  THEMES_MENU_ITEM_ID,
  TOPICS_MENU_ITEM_ID,
} from "fixtures/elementIDs";
import { POST_ANALYTICS_ROUTE } from "pages/PostAnalytics/postAnalyticsFixtures";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { POST_CALENDAR_ROUTE } from "pages/Posts/utils/postCalendarFixtures";
import { POST_SCREEN_TAB } from "pages/Posts/utils/useGetAllowedScreens";
import { ReactNode } from "react";
import {
  MdBusiness,
  MdOutlineCalendarToday,
  MdOutlineCategory,
  MdOutlineFeed,
  MdOutlineImage,
  MdOutlinePeople,
  MdStorefront,
  MdTimeline,
  MdWebAsset,
} from "react-icons/md";
import { To } from "react-router-dom";
import { POST_SCREENS, PostScreen } from "store/posts/postConstants";

// TODO: Define all routes in a fixture file somewhere, and import the route vars here. That way routes can
// be updated easily, instead of needing to do a global find/replace in the entire repo for a non-specific
// word (like 'posts', 'dashboard', etc).
export const BUSINESS_SIDEBAR_SECTIONS = [
  "analytics-dashboard",
  "social-posts",
  "post-calendar",
  "topics",
  "library",
  "business",
] as const;

export const AGENCY_SIDEBAR_SECTIONS = [
  "dashboard",
  "agency-team",
  "white-label",
] as const;

const SIDEBAR_CHILDREN = [
  "library-media",
  "themes",
  "business-profile",
  "branding",
  "integrations",
  "team",
  "clients",
  "memory",
] as const;

export type SidebarSectionKey = (
  | typeof BUSINESS_SIDEBAR_SECTIONS
  | typeof AGENCY_SIDEBAR_SECTIONS
)[number];
export type SidebarChildKey = (typeof SIDEBAR_CHILDREN)[number];

export type SidebarItemKey = SidebarSectionKey | SidebarChildKey;
export type SidebarItemConfig = {
  label: ReactNode;
  path: To;
  id?: string;
  icon?: ReactNode;
  behindFlag?: keyof AppFeatures;
  parentKey?: SidebarSectionKey;
  needsSomePermission?: PermissionString[];
  mustBeAgencyMember?: boolean;
};

export const POST_SCREENS_ANIMATE: {
  [key in SidebarSectionKey]?: PostScreen[];
} = {
  "social-posts": ["draft"],
  "post-calendar": ["scheduled", "published"],
};
export const ANIMATE_KEYS = Object.keys(POST_SCREENS_ANIMATE);

export const SIDEBAR_ITEM_CONFIG: Record<SidebarItemKey, SidebarItemConfig> = {
  "analytics-dashboard": {
    label: <span id={ANALYTICS_ITEM_ID}>Dashboard</span>,
    icon: <MdTimeline />,
    path: { pathname: POST_ANALYTICS_ROUTE },
  },
  "social-posts": {
    label: <span id={POST_MENU_ITEM_ID}>Posts</span>,
    icon: <MdOutlineImage />,
    path: {
      pathname: POSTS_PAGE_ROUTE,
      search: `${POST_SCREEN_TAB}=${POST_SCREENS[0]}`,
    },
  },
  "post-calendar": {
    label: "Calendar",
    behindFlag: "post-calendar",
    id: CALENDAR_PAGE_NAV_ID,
    icon: <MdOutlineCalendarToday />,
    needsSomePermission: [
      "post_status_schedule_read",
      "post_status_publish_read",
    ],
    path: POST_CALENDAR_ROUTE,
  },
  library: {
    label: "Library",
    icon: <MdOutlineCategory />,
    path: "/library",
    needsSomePermission: ["business_details_read"],
  },
  topics: {
    label: <span id={TOPICS_MENU_ITEM_ID}>Topics</span>,
    icon: <MdOutlineFeed />,
    path: "/topics",
    needsSomePermission: ["business_details_read"],
  },
  themes: {
    label: <span id={THEMES_MENU_ITEM_ID}>Themes</span>,
    parentKey: "library",
    path: "/themes",
  },
  "library-media": {
    label: "Media",
    parentKey: "library",
    path: "/library-media",
  },
  memory: {
    label: "Memory",
    parentKey: "library",
    path: "/memory",
  },
  business: {
    label: "Business",
    icon: <MdStorefront />,
    path: "/business",
  },
  "business-profile": {
    label: <span id={BUSINESS_PROFILE_MENU_ITEM_ID}>Profile</span>,
    parentKey: "business",
    path: "/business-profile",
    needsSomePermission: ["business_details_read"],
  },
  branding: {
    label: <span id={BRANDING_MENU_ITEM_ID}>Branding</span>,
    parentKey: "business",
    path: "/branding",
    needsSomePermission: ["business_details_read"],
  },
  integrations: {
    label: <span id={SOCIALS_MENU_ITEM_ID}>Socials</span>,
    parentKey: "business",
    path: "/integrations",
    needsSomePermission: ["posting_schedule_write", "socials_write"],
  },
  team: {
    label: <span id={TEAMS_MENU_ITEM_ID}>Team</span>,
    parentKey: "business",
    path: "/team",
    needsSomePermission: ["business_team_members_read"],
  },
  clients: {
    label: <span id={CLIENTS_MENU_ITEM_ID}>Clients</span>,
    parentKey: "business",
    path: "/clients",
    needsSomePermission: ["business_clients_read"],
    mustBeAgencyMember: true,
  },
  dashboard: {
    label: "Businesses",
    icon: <MdBusiness />,
    path: "/dashboard",
  },
  "agency-team": {
    label: "Team",
    icon: <MdOutlinePeople />,
    path: "/agency-team",
  },
  "white-label": {
    label: "Whitelabel",
    icon: <MdWebAsset />,
    path: "/white-label",
  },
};

export const SECTION_CHILDREN_MAPPING = (
  Object.entries(SIDEBAR_ITEM_CONFIG) as [SidebarItemKey, SidebarItemConfig][]
).reduce(
  (prev, [key, config]) => {
    if (!config.parentKey) {
      return prev;
    }

    if (!prev[config.parentKey]) {
      prev[config.parentKey] = [];
    }
    prev[config.parentKey]!.push(key as SidebarChildKey);
    return prev;
  },
  {} as {
    [key in SidebarItemKey]?: SidebarChildKey[];
  }
);
