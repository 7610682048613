import { Empty } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { PageLoader } from "designSystem/Loader";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk } from "store/posts/postActions";
import { POST_SCREENS, PostScreen } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import PostAlerts from "./components/PostAlerts";
import PostRejectionFeedbackModal from "./components/PostRejectionFeedbackModal";
import PostsPage from "./PostsPage";
import { useGetAllowedScreens } from "./utils/useGetAllowedScreens";

const FETCH_NEXT_PAGE_THRESHOLD = 2000;

/**
 * Responsible for:
 * - Get post data for a specific status
 * - Prevent rendering the post list until the status URL param is accurate
 * - Choosing the correct component for rendering the post list
 */
const PostsPageWrapper = () => {
  const { realm } = useAppContext();
  const { userPermissions } = useUserPermissions();
  const {
    screenParam,
    canSeePosts,
    visibleScreenKeys,
    canAccessScreen,
    setToDefaultScreen,
  } = useGetAllowedScreens();

  const dispatch = useAppDispatch();
  const { currentBusiness } = useAppSelector((state) => state.user);
  const postData = useAppSelector(selectPostStore);

  const postContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (!screenParam || !visibleScreenKeys.includes(screenParam as PostScreen)) &&
      setToDefaultScreen();
  }, [screenParam, visibleScreenKeys]);

  useEffect(() => {
    if (currentBusiness.id && canAccessScreen("scheduled")) {
      // Fetching scheduled posts because position is needed for post next option
      dispatch(getPostsThunk({ postScreen: "scheduled" }));
    }
  }, [currentBusiness.id]);

  useEffect(() => {
    if (
      screenParam &&
      POST_SCREENS.includes(screenParam as PostScreen) &&
      !postData[screenParam as PostScreen]?.isLoading &&
      canAccessScreen(screenParam as PostScreen)
    ) {
      dispatch(
        getPostsThunk({
          postScreen: screenParam as PostScreen,
          limitByPermissions: realm.isMarky ? undefined : userPermissions,
        })
      );
    }
  }, [screenParam]);

  useLayoutEffect(() => {
    postContainerRef.current?.scrollTo(0, 0);
  }, [screenParam]);

  const getNextPage = () => {
    if (
      screenParam &&
      !postData[screenParam].isLoading &&
      postContainerRef.current
    ) {
      const { nextPageUrl } = postData[screenParam];

      if (nextPageUrl) {
        const { scrollHeight, scrollTop, clientHeight } =
          postContainerRef.current;
        const distanceToEnd = scrollHeight - scrollTop - clientHeight;

        distanceToEnd < FETCH_NEXT_PAGE_THRESHOLD &&
          dispatch(getPostsThunk({ postScreen: screenParam, nextPageUrl }));
      }
    }
  };

  return canSeePosts && screenParam ? (
    <div
      className="min-h-0 flex-grow flex flex-col overflow-y-auto overflow-x-hidden pb-48"
      ref={postContainerRef}
      onScroll={getNextPage}
    >
      <PostAlerts />
      <PostsPage postScreen={screenParam} />
      <PostRejectionFeedbackModal />
    </div>
  ) : canSeePosts ? (
    <PageLoader />
  ) : (
    <Empty className="h-full flex flex-col justify-center" />
  );
};

export default PostsPageWrapper;
