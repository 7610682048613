import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ContentTopic } from "api/config/chalice-api";
import {
  CURRENT_BUSINESS_ID,
  JWT_TOKEN,
  SHOW_ONBOARDING_TOUR,
} from "fixtures/localStorageKeys";
import { sortBy } from "lodash";
import { RootState } from "store";
import {
  createNewBusiness,
  deleteCurrentBusiness,
  mergeOnboardingTracking,
  updateBusinessDetails,
} from "store/business/businessActions";
import { Business, EMPTY_BUSINESS } from "store/business/businessConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import {
  fetchRealmDetails,
  fetchSearchTerms,
  getUserDetails,
  TypesafeChaliceUser,
  updateRealmDetails,
  updateUserDetails,
} from "./userActions";
import {
  INITIAL_USER_STATE,
  ShowOnboardingTour,
  UserInfo,
} from "./userConstants";

const mergeUserInfo = (
  storedUser: UserInfo,
  newUser: TypesafeChaliceUser
): UserInfo => ({
  ...storedUser,
  ...newUser,
  isAnonymous: !newUser.email,
});

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_USER_STATE,
  reducers: {
    setShowOnboardingTour: (
      state,
      { payload }: { payload: ShowOnboardingTour }
    ) => {
      payload
        ? localStorage.setItem(SHOW_ONBOARDING_TOUR, JSON.stringify(payload))
        : localStorage.removeItem(SHOW_ONBOARDING_TOUR);

      state.showOnboardingTour = payload;
    },
    setOnboardingTourStep: (state, { payload }: { payload: number | null }) => {
      state.onboardingTourStep = payload;
    },
    setBusinessUpdated: (state, action) => {
      state.businessUpdated = { ...state.businessUpdated, ...action.payload };
    },
    setIsAnonymous: (state, { payload }: { payload: boolean }) => {
      state.userInfo.isAnonymous = payload;
    },
    setUserInfo: (
      state,
      { payload }: { payload: { user: TypesafeChaliceUser; token?: string } }
    ) => {
      state.userInfo = mergeUserInfo(state.userInfo, payload.user);
      if (payload.token) {
        state.token = payload.token;
        localStorage.setItem(JWT_TOKEN, payload.token);
      }
    },
    updateCurrentBusiness: (state, action: { payload: Partial<Business> }) => {
      state.currentBusiness = { ...state.currentBusiness, ...action.payload };
      state.businesses = state.businesses.map((business) => {
        if (business.id === action.payload.id) {
          return state.currentBusiness;
        }
        return business;
      });
    },
    updateBusinessTopics: (state, { payload }: { payload: ContentTopic[] }) => {
      state.currentBusiness = { ...state.currentBusiness, topics: payload };
    },
    setCurrentBusiness: (state, { payload }) => {
      const business = state.businesses.find(({ id }) => id === payload);
      state.currentBusiness = business || EMPTY_BUSINESS;
      state.isAgencyMember = state.currentBusiness.owner.is_agency ?? false;
    },
    updateIntegrationItem: (state, action) => {
      const updatedIntegrations = state.currentBusiness.integrations.map(
        (integration) =>
          integration.id === action.payload.id ? action.payload : integration
      );
      state.currentBusiness = {
        ...state.currentBusiness,
        integrations: updatedIntegrations,
      };
      state.businesses = state.businesses.map((business) => {
        if (business.id === state.currentBusiness.id) {
          return {
            ...state.currentBusiness,
            integrations: updatedIntegrations,
          };
        }
        return business;
      });
    },
    setQueues: (state, action) => {
      state.currentBusiness = {
        ...state.currentBusiness,
        queues: action.payload,
      };
      state.businesses = state.businesses.map((business) =>
        business.id === state.currentBusiness.id
          ? state.currentBusiness
          : business
      );
    },
    removeIntegration: (state, action) => {
      const updatedIntegrations = state.currentBusiness.integrations.filter(
        ({ type }) => type !== action.payload
      );
      state.currentBusiness = {
        ...state.currentBusiness,
        integrations: updatedIntegrations,
      };
      state.businesses = state.businesses.map((business) => {
        if (business.id === state.currentBusiness.id) {
          return {
            ...state.currentBusiness,
            integrations: updatedIntegrations,
          };
        }
        return business;
      });
    },
    resetUser: () => INITIAL_USER_STATE,
    editTeamMember: (state, { payload }) => {
      const updatedCurrentBusiness = {
        ...state.currentBusiness,
        members: state.currentBusiness.members.map((member) => {
          if (member.id === payload.id) {
            return { ...member, ...payload };
          }
          return member;
        }),
      };
      state.currentBusiness = updatedCurrentBusiness;
      state.businesses = state.businesses.map((business) => {
        if (business.id === state.currentBusiness.id) {
          return updatedCurrentBusiness;
        }
        return business;
      });
    },
    removeTeamMember: (state, { payload }) => {
      const updatedCurrentBusiness = {
        ...state.currentBusiness,
        members: state.currentBusiness.members?.filter(
          ({ role_id }) => role_id !== payload
        ),
      };
      state.currentBusiness = updatedCurrentBusiness;
      state.businesses = state.businesses.map((business) => {
        if (business.id === state.currentBusiness.id) {
          return updatedCurrentBusiness;
        }
        return {
          ...business,
          members: business.members.filter(
            ({ role_id }) => role_id !== payload
          ),
        };
      });
    },
    removeBusinessById: (state, { payload }) => {
      state.businesses = state.businesses.filter(({ id }) => id !== payload);
    },
    autoSetCurrentBusiness: (state) => {
      const businesses = state.businesses;

      const defaultBusinessId =
        localStorage.currentBusiness ||
        state.currentBusiness.id ||
        businesses[0]?.id;

      const businessObject =
        businesses.find(({ id }) => id === defaultBusinessId) ||
        businesses[0] ||
        EMPTY_BUSINESS;

      if (
        !localStorage.currentBusiness ||
        localStorage.currentBusiness !== businessObject.id
      ) {
        localStorage.setItem(CURRENT_BUSINESS_ID, businessObject.id);
      }

      state.currentBusiness = businessObject;

      state.isAgencyMember = state.currentBusiness.owner.is_agency ?? false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const { user, businesses, owned_realm } = action.payload;
        state.userLoading = false;
        state.businesses = businesses ?? [];
        state.ownedRealm = owned_realm;

        state.userInfo = mergeUserInfo(state.userInfo, user);

        state.isAgencyOwner = localStorage.isPreviewMode
          ? false
          : user.is_agency ?? false;

        trackEvent("user_data", { userInfo: state.userInfo });
      })
      .addCase(updateBusinessDetails.pending, (state, action) => {
        if (!action.meta.arg.isOnboarding) {
          // Optimistically merge preferences so results of tallying things (like scheduled posts)
          // will be updated before the request returns
          state.currentBusiness.onboarding_tracking = mergeOnboardingTracking({
            currentBusiness: state.currentBusiness,
            ...action.meta.arg,
          });
        }
      })
      .addCase(updateBusinessDetails.fulfilled, (state, action) => {
        if (!action.payload) {
          // Request aborted
          return;
        }
        state.currentBusiness = { ...state.currentBusiness, ...action.payload };
        state.businesses = state.businesses.map((business) => {
          if (business.id === action.payload?.id) {
            return { ...business, ...action.payload };
          }
          return business;
        });
      })
      .addCase(createNewBusiness.fulfilled, (state, action) => {
        state.currentBusiness = {
          ...action.payload.business,
        };
        state.isAgencyMember = state.currentBusiness.owner.is_agency ?? false;
        localStorage.setItem(CURRENT_BUSINESS_ID, action.payload.business.id);
      })
      .addCase(deleteCurrentBusiness.fulfilled, (state) => {
        const defaultBusinessId = state.businesses.filter(
          (b) => b.id !== state.currentBusiness.id
        )[0]?.id;

        if (defaultBusinessId) {
          localStorage.setItem(CURRENT_BUSINESS_ID, defaultBusinessId);
        } else {
          localStorage.removeItem(CURRENT_BUSINESS_ID);
        }

        state.currentBusiness =
          state.businesses.find(({ id }) => id === defaultBusinessId) ||
          EMPTY_BUSINESS;
      })
      .addCase(fetchSearchTerms.fulfilled, (state, action) => {
        state.imageSearchTerms = action.payload;
      })
      .addCase(updateUserDetails.fulfilled, (state, { payload }) => {
        state.userInfo = mergeUserInfo(state.userInfo, payload);
        trackEvent("user_data", { userInfo: state.userInfo });
      })
      .addCase(updateRealmDetails.fulfilled, (state, { payload }) => {
        if (payload) {
          state.ownedRealm = payload;
        }
      })
      .addCase(fetchRealmDetails.fulfilled, (state, { payload }) => {
        if (payload) {
          state.ownedRealm = payload;
        }
      });
  },
});

export const queueIdGetter = (state: RootState) =>
  state.user.currentBusiness.queues?.[0]?.id;

export const queuesGetter = (state: RootState) =>
  state.user.currentBusiness.queues;
export const currentBusinessGetter = (state: RootState) =>
  state.user.currentBusiness;
export const currentBusinessOnboardingTracking = createSelector(
  (state: RootState) => state.user.currentBusiness,
  (currentBusiness) => currentBusiness.onboarding_tracking ?? {}
);
export const imageSearchTermsGetter = (state: RootState) =>
  state.user.imageSearchTerms;

export const integrationsGetter = (state: RootState) =>
  state.user.currentBusiness.integrations;

export const selectUserInfo = (state: RootState) => state.user.userInfo;

export const sortedBusinessesGetter = createSelector(
  (state: RootState) => state.user.businesses,

  (businesses) => sortBy(businesses, "title")
);

export const currentUserBusinessesGetter = createSelector(
  [sortedBusinessesGetter, (state: RootState) => state.user.userInfo.id],
  (businesses, userId) =>
    sortBy(businesses, "title").filter((b) => b.owner.id === userId)
);

export const integrationsTypeGetter = createSelector(
  integrationsGetter,
  (integrations) => integrations.map(({ type }) => type)
);

export const isBusinessOwner = (state: RootState) =>
  state.user.userInfo.id === state.user.currentBusiness?.owner?.id;

export const {
  setIsAnonymous,
  setUserInfo,
  setQueues,
  resetUser,
  updateCurrentBusiness,
  updateBusinessTopics,
  removeIntegration,
  setCurrentBusiness,
  updateIntegrationItem,
  removeTeamMember,
  editTeamMember,
  setBusinessUpdated,
  setOnboardingTourStep,
  setShowOnboardingTour,
  removeBusinessById,
  autoSetCurrentBusiness,
} = userSlice.actions;
export default userSlice.reducer;
