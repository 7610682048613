import { Button, ButtonProps } from "antd";
import classNames from "classnames";

const SidebarTextButton = ({ className, ...props }: ButtonProps) => (
  <Button
    type="text"
    {...props}
    className={classNames(
      className,
      "!justify-start gap-3 text-antd-colorTextLabel max-sm:px-3"
    )}
  />
);

export default SidebarTextButton;
