import {
  BusinessOnboardingMilestone,
  BusinessSumTrackingKey,
  OnboardingTracking,
} from "components/SidebarNav/sidebarWidgets/OnboardingMilestoneTracking/onboardingMilestoneFixtures";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { currentBusinessOnboardingTracking } from "store/user/userSlice";

const useOnboardingTracking = () => {
  const onboardingTrackerFlagEnabled =
    useTypedFeatureIsOn("onboarding-tracker");

  const dispatch = useAppDispatch();
  const onboardingTracking = useAppSelector(currentBusinessOnboardingTracking);

  const { hasPermission } = useUserPermissions();
  const { realm } = useAppContext();

  const onboardingTrackerEnabled =
    realm.isMarky &&
    onboardingTrackerFlagEnabled &&
    onboardingTracking.showMilestoneTracker &&
    hasPermission("business_details_write");

  const updateOnboardingTracking = (data: OnboardingTracking) =>
    onboardingTrackerEnabled &&
    dispatch(updateBusinessDetails({ onboarding_tracking: data }));

  const incrementOnboardingTracker = (
    key: BusinessSumTrackingKey,
    amount = 1
  ) =>
    onboardingTrackerEnabled &&
    dispatch(
      updateBusinessDetails({
        onboarding_tracking: {
          [key]: (onboardingTracking[key] ?? 0) + amount,
        },
      })
    );

  return {
    onboardingTrackerEnabled,
    updateOnboardingTracking,
    incrementOnboardingTracker,
  };
};

export const completedMilestoneAtNow = () => ({
  value: true,
  updatedTimestamp: dayjs().unix(),
});

export const createCompletedMilestonesDict = (
  milestone: BusinessOnboardingMilestone
): Pick<OnboardingTracking, "onboardingMilestones"> => ({
  onboardingMilestones: { [milestone]: completedMilestoneAtNow() },
});

export default useOnboardingTracking;
