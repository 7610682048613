import { Empty } from "antd";
import { ItemType } from "antd/es/menu/interface";
import classNames from "classnames";
import BrandingChangesModal from "components/GlobalModals/BrandingChangesModal";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { pollGeneratedPostsThunk } from "store/posts/postActions";
import {
  GENERATED_POST_CONTAINER_ID,
  PostScreen,
} from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import DownloadPostsDropdown from "./components/DownloadPostsDropdown";
import GeneratedPostsEmptyScreen from "./components/GeneratedPostsEmptyScreen";
import GenerateMorePostsButton from "./components/GenerateMorePostsButton";
import LoadingIndicator from "./components/LoadingIndicator";
import PostCardList from "./components/PostCardList";
import PostLoaderSkeleton from "./components/PostLoaderSkeleton";
import PostPageOptionMenu from "./components/PostPageOptionMenu";
import PostScreenSelector from "./components/PostScreenSelector";
import PostTour from "./components/PostTour";
import ReviewStatusFilter from "./components/ReviewStatusFilter";
import ImproveYourPosts from "./ImproveYourPosts/ImproveYourPosts";
import PostSelectionProvider from "./PostSelection/PostSelectionProvider";
import ScheduledPostsList from "./ScheduledPosts/ScheduledPostsList";
import { POST_SCREEN_TAB } from "./utils/useGetAllowedScreens";

export const POSTS_PAGE_ROUTE = "/social-posts";

const PostsPage = ({ postScreen }: { postScreen: PostScreen }) => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const [, setSearchParams] = useSearchParams();

  const {
    realm,
    headerChildrenState: [, setHeaderChildren],
  } = useAppContext();
  const { hasPermission } = useUserPermissions();

  const dispatch = useAppDispatch();
  const { isAgencyMember } = useAppSelector((state) => state.user);
  const postData = useAppSelector(selectPostStore);
  const {
    isInitialized,
    postList: allPosts,
    nextPageUrl,
  } = postData[postScreen];

  const [isApplyingBrandingChanges, setIsApplyingBrandingChanges] =
    useState(false);
  const [filteredPosts, setFilteredPosts] = useState(allPosts);

  const isGeneratedTab = postScreen === "generated";
  const isScheduledTab = postScreen === "scheduled";
  const enableReviewStatusFilter = isAgencyMember && postScreen === "draft";
  const postList = enableReviewStatusFilter ? filteredPosts : allPosts;

  const VIEW_TRASH_ITEM: ItemType = {
    key: "trash",
    label: "View Trash",
    icon: <MdOutlineDelete size={16} />,
    onClick: () => setSearchParams({ [POST_SCREEN_TAB]: "rejected" }),
  };

  useEffect(() => {
    setHeaderChildren(
      <PostScreenSelector
        showUploadButton={hasPermission("upload_custom_post_write")}
      >
        {postCalendar && (
          <PostPageOptionMenu
            postList={postList}
            additionalItems={
              hasPermission("post_status_trash_read")
                ? VIEW_TRASH_ITEM
                : undefined
            }
          />
        )}
      </PostScreenSelector>
    );

    return () => setHeaderChildren(null);
  }, [postCalendar, postList]);

  useEffect(() => {
    if (
      isGeneratedTab &&
      postData.generated.loadingCount &&
      !postData.generated.isGenerating
    ) {
      dispatch(pollGeneratedPostsThunk());
    }
  }, [
    isGeneratedTab,
    postData.generated.isGenerating,
    postData.generated.loadingCount,
  ]);

  useEffect(() => {
    enableReviewStatusFilter && !allPosts.length && setFilteredPosts([]);
  }, [allPosts]);

  return (
    <AnimatePresence>
      {isInitialized ? (
        <PostSelectionProvider {...{ postScreen, postList }}>
          <div
            id={isGeneratedTab ? GENERATED_POST_CONTAINER_ID : undefined}
            className={classNames("flex flex-col gap-7 items-center", {
              "!gap-12": isScheduledTab,
              "h-full !gap-0 justify-center": !postList.length,
            })}
          >
            <div
              className={classNames(
                "flex w-full justify-center relative",
                isScheduledTab ? "-mb-12 pt-8 max-sm:pb-4" : "py-4",
                { hidden: !allPosts.length && !isScheduledTab }
              )}
            >
              <ReviewStatusFilter
                enabled={enableReviewStatusFilter}
                {...{ allPosts, setFilteredPosts }}
              />
              {realm.isMarky && !postCalendar && (
                <DownloadPostsDropdown postList={postList} />
              )}
            </div>

            {enableReviewStatusFilter &&
            allPosts.length > 0 &&
            !postList.length ? (
              <Empty
                className="my-auto"
                description="Nothing to show, try clearing your filters"
              />
            ) : isGeneratedTab &&
              (isApplyingBrandingChanges || !allPosts.length) ? (
              <GeneratedPostsEmptyScreen
                isApplyingBrandingChanges={isApplyingBrandingChanges}
              />
            ) : isScheduledTab ? (
              <ScheduledPostsList />
            ) : (
              <PostCardList
                defaultSpacing
                key={postScreen}
                postList={postList}
                className="pb-8"
              />
            )}

            {nextPageUrl && (
              <LoadingIndicator className="mx-auto">
                Loading more posts
              </LoadingIndicator>
            )}

            {isGeneratedTab &&
              (!postData.generated.isGenerating || postList.length > 0) && (
                <>
                  <GenerateMorePostsButton />
                  <ImproveYourPosts />
                  <BrandingChangesModal
                    setIsApplyingBrandingChanges={setIsApplyingBrandingChanges}
                  />
                  <PostTour />
                </>
              )}
          </div>
        </PostSelectionProvider>
      ) : (
        <PostLoaderSkeleton postScreen={postScreen} />
      )}
    </AnimatePresence>
  );
};

export default PostsPage;
