import {
  createBusiness,
  deleteBusiness,
  inferBusinessDetails,
  updateBusiness,
  UpdateBusinessBody,
} from "api/businesses";
import {
  OnboardingTracking,
  SUMMABLE_MILESTONES_ITERATABLE,
} from "components/SidebarNav/sidebarWidgets/OnboardingMilestoneTracking/onboardingMilestoneFixtures";
import { completedMilestoneAtNow } from "hooks/useOnboardingTracking";
import { createAppAsyncThunk } from "store/storeHelpers";
import { removeBusinessById } from "store/user/userSlice";
import { Business, CreateBusinessPayload } from "./businessConstants";

export const createNewBusiness = createAppAsyncThunk(
  "integrations/createNewBusiness",
  async (
    {
      onboardingTrackerFlagEnabled,
      ...payload
    }: CreateBusinessPayload & {
      onboardingTrackerFlagEnabled: boolean;
    },
    { getState }
  ) => {
    const { isAgencyOwner, businesses } = getState().user;
    const { details, topics } = await inferBusinessDetails({
      content: payload.summary,
    });

    return createBusiness({
      ...details,
      ...payload,
      topics,
      onboarding_tracking: {
        showMilestoneTracker:
          onboardingTrackerFlagEnabled && !isAgencyOwner && !businesses.length,
      },
    });
  }
);

export const updateBusinessDetails = createAppAsyncThunk(
  "integrations/updateBusinessDetails",
  async (
    {
      id,
      onboarding_tracking: _tracking,
      ...data
    }: UpdateBusinessBody & {
      id?: string;
      isOnboarding?: boolean;
    },
    { getState }
  ) => {
    const { currentBusiness } = getState().user;
    const businessId = id || currentBusiness.id;
    if (!businessId) {
      return;
    }

    return updateBusiness(businessId, {
      ...data,
      // currentBusiness.onboarding_tracking is set optimistically before this action runs
      onboarding_tracking: currentBusiness.onboarding_tracking,
    });
  }
);

export const mergeOnboardingTracking = ({
  currentBusiness: { onboarding_tracking: currentOnboardingTracking = {} },
  onboarding_tracking: {
    onboardingMilestones = {},
    ...updateOnboardingTracking
  } = {},
  ...updateBusinessPayload
}: UpdateBusinessBody & {
  currentBusiness: Business;
}): OnboardingTracking => {
  const newMilestones = {
    ...currentOnboardingTracking.onboardingMilestones,
    ...onboardingMilestones,
  };

  if (updateBusinessPayload.themes && !onboardingMilestones.hasEditedThemes) {
    newMilestones.hasEditedThemes = completedMilestoneAtNow();
  }
  if (updateBusinessPayload.topics && !onboardingMilestones.hasEditedTopics) {
    newMilestones.hasEditedTopics = completedMilestoneAtNow();
  }

  SUMMABLE_MILESTONES_ITERATABLE.forEach(
    ({ sumKey, milestoneKey, needsSum }) => {
      if (
        !newMilestones[milestoneKey] &&
        (updateOnboardingTracking[sumKey] ?? 0) >= needsSum
      ) {
        newMilestones[milestoneKey] = completedMilestoneAtNow();
      }
    }
  );

  return {
    ...currentOnboardingTracking,
    ...updateOnboardingTracking,
    onboardingMilestones: newMilestones,
  };
};

export const deleteCurrentBusiness = createAppAsyncThunk(
  "integrations/deleteCurrentBusiness",
  async (_arg: undefined, { getState, dispatch }) => {
    const businessId = getState().user.currentBusiness.id;
    return dispatch(deleteBusinessById(businessId));
  }
);

export const deleteBusinessById = createAppAsyncThunk(
  "integrations/deleteBusinessById",
  async (businessId: string, { dispatch }) => {
    await deleteBusiness(businessId);
    dispatch(removeBusinessById(businessId));
  }
);
