import { Button, Form, Modal, Switch, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { ContentTopic } from "api/config/chalice-api";
import { genericError } from "fixtures/globalConstants";
import { BusinessDetails } from "pages/NewBusinessOnboarding/businessOnboardingConstants";
import ContentTopics from "pages/NewBusinessOnboarding/onboardingStepper/sections/ContentTopics";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";

type TopicsForm = Required<Pick<BusinessDetails, "topics">>;

const ConfirmNewTopicsModal = ({
  topics,
  close,
}: {
  topics: ContentTopic[];
  close: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { currentBusiness } = useAppSelector((state) => state.user);
  const [contentTopics] = useForm<TopicsForm>();

  const [shouldDeleteOldTopics, setShouldDeleteOldTopics] = useState(false);
  const [disableOtherTopics, setDisableOtherTopics] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addContentTopics = async ({ topics }: TopicsForm) => {
    setIsLoading(true);
    try {
      let saveTopics;
      if (shouldDeleteOldTopics) {
        saveTopics = topics;
      } else if (disableOtherTopics) {
        saveTopics = currentBusiness.topics
          .map(
            (topic) =>
              ({
                ...topic,
                enabled: false,
              }) as ContentTopic
          )
          .concat(topics);
      } else {
        saveTopics = currentBusiness.topics.concat(topics);
      }

      await dispatch(
        updateBusinessDetails({
          topics: saveTopics,
        })
      );

      message.success("Content topics updated successfully");
      close();
    } catch (error) {
      genericError(error);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open
      footer={
        <div className="flex gap-4">
          <Button onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="flex-grow"
            loading={isLoading}
            disabled={!topics.length}
            htmlType="submit"
            form="contentTopicsForm"
          >
            Add Topics
          </Button>
        </div>
      }
      title="Review New Content Topics"
      closable={false}
    >
      <div className="h-[75vh] flex flex-col gap-4">
        <h6 className="font-medium">
          The AI creates your posts based on these topics.
        </h6>

        <Form
          id="contentTopicsForm"
          className="flex-grow -mr-4 py-4 pr-4 max-h-[60vh] overflow-auto"
          initialValues={{ topics }}
          form={contentTopics}
          onFinish={addContentTopics}
        >
          <ContentTopics isFromNewTopicModal form={contentTopics} />
        </Form>
        <div className="[&_>_div]:space-x-2 space-y-2">
          <div onClick={() => setShouldDeleteOldTopics(!shouldDeleteOldTopics)}>
            <Switch checked={shouldDeleteOldTopics} disabled={isLoading} />
            <label htmlFor="delete-old-topics">
              <i className="text-antd-red">Delete</i> all other topics
            </label>
          </div>

          <div onClick={() => setDisableOtherTopics(!disableOtherTopics)}>
            <Switch
              disabled={isLoading || shouldDeleteOldTopics}
              checked={disableOtherTopics}
            />
            <label>Disable all other topics</label>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmNewTopicsModal;
