import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select } from "antd";
import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { CURRENT_BUSINESS_ID } from "fixtures/localStorageKeys";
import useAddNewBusiness from "hooks/useAddNewBusiness";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { sortBy } from "lodash";
import { PostStatusPill } from "pages/AgencyDashboard/components/PostStatuses";
import { useCallback, useEffect, useMemo } from "react";
import { LuChevronsUpDown } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { Business } from "store/business/businessConstants";
import { resetImageLibrary } from "store/imageLibrary/imageLibrarySlice";
import { resetPostData, setPostCountsForBusiness } from "store/posts/postSlice";
import { setCurrentBusiness } from "store/user/userSlice";
import AvatarInitials from "./AvatarInitials";

const BusinessSelector = () => {
  const navigate = useNavigate();
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const { realm } = useAppContext();
  const { userPermissions } = useUserPermissions();

  const { handleNewBusinessCreation } = useAddNewBusiness();

  const dispatch = useDispatch();
  const { businesses, currentBusiness, isAgencyMember } = useAppSelector(
    (state) => state.user
  );

  const handleBusinessChange = useCallback(
    (businessId: string) => {
      localStorage.setItem(CURRENT_BUSINESS_ID, businessId);
      dispatch(setCurrentBusiness(businessId));
      navigate(defaultBusinessRoute);
    },
    [defaultBusinessRoute]
  );

  const businessOptions = useMemo(
    () =>
      sortBy(businesses, (business) => business.title.toLowerCase()).map(
        (business) => ({
          label: (
            <div className="flex justify-between items-center">
              <BusinessLabel business={business} />
              {isAgencyMember && currentBusiness.id !== business.id && (
                <PostStatusPill
                  statusKey="CHANGES_REQUESTED"
                  count={
                    business.posts_data.review_status_counts.CHANGES_REQUESTED
                      ?.LIKED ?? 0
                  }
                />
              )}
            </div>
          ),
          value: business.id,
        })
      ),
    [businesses, currentBusiness, isAgencyMember]
  );

  useEffect(() => {
    dispatch(
      setPostCountsForBusiness({
        business: currentBusiness,
        limitByPermissions: realm.isMarky ? undefined : userPermissions,
      })
    );

    return () => {
      dispatch(resetPostData());
      dispatch(resetImageLibrary());
    };
  }, [currentBusiness.id]);

  if (!realm.isMarky && businesses.length === 1) {
    return (
      <BusinessLabel
        business={businesses[0]}
        className="py-1.5 px-3 rounded-lg bg-antd-colorBgContainer border border-antd-colorBorder m-2.5"
      />
    );
  }

  return (
    <Select
      value={currentBusiness.id || undefined}
      placeholder="Select Business"
      data-testid="business-selector"
      options={businessOptions}
      suffixIcon={
        <LuChevronsUpDown className="!text-antd-colorTextSecondary" size={16} />
      }
      className="h-[50px] !w-[unset] mx-1 mb-3 max-sm:mt-2 [&_.ant-select-arrow]:!h-4 z-antd-popup"
      onChange={handleBusinessChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          {realm.isMarky && (
            <Button
              onClick={() => handleNewBusinessCreation()}
              className="w-full"
              icon={<PlusOutlined />}
            >
              Add New Business
            </Button>
          )}
        </>
      )}
    />
  );
};

const BusinessLabel = ({
  business,
  className,
}: {
  business: Business;
  className?: string;
}) => (
  <div
    className={classNames("flex gap-3 items-center overflow-hidden", className)}
  >
    <AvatarInitials business={business} size={40} />
    <span className="font-medium overflow-ellipsis overflow-hidden whitespace-nowrap">
      {business.title}
    </span>
  </div>
);

export default BusinessSelector;
