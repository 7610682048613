import { BusinessResponse, ReviewStatus } from "api/config/chalice-api";
import { OnboardingTracking } from "components/SidebarNav/sidebarWidgets/OnboardingMilestoneTracking/onboardingMilestoneFixtures";
import { Memory, PostStatus } from "store/posts/postConstants";
import {
  Integration,
  IntegrationType,
  UserInfo,
} from "store/user/userConstants";

export type PostCount = Record<PostStatus, number>;

export type ReviewStatusCounts = Record<ReviewStatus, Partial<PostCount>>;

type Queue = {
  id: string;
  created_at: string;
  business_id: string;
  schedule_timeslots: string[];
  schedule_jitter: number;
  next_publish_minute_timestamp: number;
};

export type Palette = {
  background_color: string;
  accent_color: string;
  text_color: string;
};

export type Brand = {
  palettes?: Palette[];
  logo?: string;
  avatar?: string | null;
  header_font?: string | null;
  body_font?: string | null;
  themes?: string[];
};

export type BusinessBase = Omit<
  BusinessResponse,
  "brand" | "integrations" | "onboarding_tracking"
> & {
  title: string;
  min_caption_length: number;
  max_caption_length: number;
  ctas: string[];
  memories?: Memory[];
  integrations: Integration[];
  onboarding_tracking?: OnboardingTracking;
};

export type CreateBusinessPayload = Partial<BusinessBase> & {
  daily_publishing_time: string;
  brand: Brand;
  summary: string;
};

export type PublishedLinksType = Record<IntegrationType, string>;

export type Business = BusinessBase & {
  id: string;
  created_at: string;
  user_id: string;
  brand: Brand;
  queues: Queue[];
  posts_data?: {
    status_counts: Partial<PostCount>;
    last_published_links: { [key in IntegrationType]?: string | null };
    last_published_time: string;
    review_status_counts: ReviewStatusCounts;
  };
  owner: {
    id: string;
  } & Partial<UserInfo>;
};

export const EMPTY_BUSINESS: Business = {
  id: "",
  created_at: "",
  user_id: "",
  title: "",
  min_caption_length: 0,
  max_caption_length: 0,
  ctas: [],
  topics: [],
  queues: [],
  integrations: [],
  members: [],
  brand: {},
  posts_data: {
    status_counts: {},
    last_published_links: {},
    last_published_time: "",
    review_status_counts: {
      APPROVED: {},
      CHANGES_REQUESTED: {},
      READY_FOR_REVIEW: {},
    },
  },
  owner: { id: "", is_agency: false },
  preferences: { generation_batch_size: 0, image_sources: ["unsplash_free"] },
};
