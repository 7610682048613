import { ComponentProps, FunctionComponent } from "react";
import OnboardingMilestoneTracker from "./OnboardingMilestoneTracking/OnboardingMilestoneTracker";

export type WidgetComponent = FunctionComponent<
  {
    isMobileLogo?: boolean;
  } & ComponentProps<"div">
>;

type WidgetKey = "onboardingTracker" | "postTank";

export const WIDGETS: Record<WidgetKey, WidgetComponent> = {
  onboardingTracker: OnboardingMilestoneTracker,
  postTank: ({ isMobileLogo: asMobileLogo, ...props }) => (
    <div {...props}>{asMobileLogo ? "Post Tank Logo" : "Post Tank Card"}</div>
  ),
};
