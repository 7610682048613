import { PageLoader } from "designSystem/Loader";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const IndexRoutePage = () => {
  const navigate = useNavigate();

  const defaultBusinessRoute = useDefaultBusinessRoute();

  const { userInfo, token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }

    if (!userInfo.id) {
      return;
    }

    navigate(userInfo.is_agency ? "/dashboard" : defaultBusinessRoute);
  }, [userInfo, token, defaultBusinessRoute]);

  return <PageLoader />;
};

export default IndexRoutePage;
