import { OnboardingTracking } from "components/SidebarNav/sidebarWidgets/OnboardingMilestoneTracking/onboardingMilestoneFixtures";
import { Business } from "store/business/businessConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { chaliceClient } from "./baseClients";
import {
  UpdateBusinessBody as ChaliceUpdateBusinessBody,
  ContentTopic,
  Paths,
  WritingTemplate,
} from "./config/chalice-api";

export type UpdateBusinessBody = Omit<
  ChaliceUpdateBusinessBody,
  "onboarding_tracking"
> & { onboarding_tracking?: OnboardingTracking };

export const createBusiness = async (
  body: Paths.V1BusinessCreate.RequestBody & Paths.V1BusinessUpdate.RequestBody
) => {
  const response = await chaliceClient.post<{ business: Business }>(
    "/business",
    body
  );
  trackEvent("created_business");
  return response;
};

let controller: AbortController | null = null;
export const updateBusiness = async (
  id: string,
  body: Paths.V1BusinessUpdate.RequestBody
) => {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();

  return chaliceClient.put<Business>(`/business/${id}`, body, {
    signal: controller.signal,
  });
};

export const updateOwner = ({
  businessId,
  ownerId,
}: {
  businessId: string;
  ownerId: string;
}) =>
  chaliceClient.post(`/business/${businessId}/transfer-ownership`, {
    new_owner_id: ownerId,
  });

export const inferBusinessDetails = (
  body: Paths.V1BusinessInferDetails.RequestBody
) =>
  chaliceClient.post<{ details: Business; topics: ContentTopic[] }>(
    "/business/infer-details",
    body
  );

export const getColorsFromLogo = (logoURL: string) =>
  chaliceClient.get<{ [name: string]: string }>(
    `/business/brand/palette/from-logo`,
    {
      params: {
        logo_url: logoURL,
      },
    }
  );

export const getColorsFromBrandColor = (brandColor: string) =>
  chaliceClient.get("/business/brand/palette/from-color", {
    params: {
      accent_color: brandColor,
    },
  });

export const deleteBusiness = (businessId: string) =>
  chaliceClient.delete(`/business/${businessId}`);

export const fetchImageSearchTerms = async (businessId: string) => {
  const response = await chaliceClient.get<{ search_terms: string[] }>(
    `/business/${businessId}/generate-search-terms`
  );
  return response.search_terms;
};

export const fetchWritingTemplates = () =>
  chaliceClient.get<{ templates: WritingTemplate[] }>(
    "/templates/writing-templates"
  );

export const sendInviteEmail = (businessId: string, memberId: string) =>
  chaliceClient.post(`business/${businessId}/send-invite`, {
    invitee_user_id: memberId,
  });
