import { Divider, Dropdown } from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import AvatarInitials from "components/AvatarInitials";
import UpgradePlanButton from "components/UpgradePlan/UpgradePlanButton";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { Crisp } from "crisp-sdk-web";
import { ReactNode, useMemo, useState } from "react";
import {
  MdArrowBack,
  MdChatBubbleOutline,
  MdHelpOutline,
  MdLogout,
  MdOpenInNew,
  MdOutlineAccountCircle,
  MdOutlineAttachMoney,
  MdOutlineSmartToy,
  MdSettings,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { trackEvent } from "utils/eventTracking/trackEvents";
import ProductTourButton from "./ProductTourButton";
import ReferralButton from "./ReferralButton";
import Settings from "./Settings";
import SidebarTextButton from "./SidebarTextButton";
import SidebarWidgetContainer from "./sidebarWidgets/SidebarWidgetContainer";

type MenuItemsGroup = {
  label: string;
  icon: ReactNode;
  items: (MenuItemType & { hidden?: boolean })[];
  order: number;
  testId?: string;
  topDivider?: boolean;
};

const openInNewTab = (url: string) => window.open(url, "_blank");

const HELP_MENU_ITEMS: MenuItemsGroup = {
  label: "Help",
  icon: <MdHelpOutline size={20} />,
  order: 0,
  items: [
    {
      key: "help-center",
      label: "Help Center",
      icon: <MdOpenInNew />,
      onClick: () => openInNewTab("https://help.mymarky.ai/"),
    },
    {
      key: "community",
      label: "Marky Community",
      icon: <MdOpenInNew />,
      onClick: () =>
        openInNewTab("https://links.mymarky.ai/help-panel-facebook-community"),
    },
    {
      key: "whats-new",
      label: "What's New",
      icon: <MdOpenInNew />,
      onClick: () => openInNewTab("https://roadmap.mymarky.ai/announcements"),
    },
    {
      key: "chat",
      label: "Chat with us",
      icon: <MdChatBubbleOutline />,
      onClick: () => Crisp.chat.open(),
    },
    {
      key: "feedback",
      label: "Give Feedback",
      icon: <MdChatBubbleOutline />,
      onClick: () =>
        trackEvent("refiner_launch_survey", {
          refinerArgs: {
            surveyId: "4add4950-356f-11ef-8319-cb5fb9bc9e40",
            triggerSurvey: true,
          },
        }),
    },
  ],
};

const SidebarBottomMenu = () => {
  const { realm } = useAppContext();
  const navigate = useNavigate();
  const { userInfo, isAgencyOwner, currentBusiness, token } = useAppSelector(
    (state) => state.user
  );
  const agentChatEnabled = useTypedFeatureIsOn("agent-chat");

  const showReferAFriendButton = useMemo(() => {
    const { plan } = userInfo.subscription || {};
    return realm.isMarky && plan && /professional|personal/.test(plan);
  }, [realm.isMarky, userInfo.subscription]);
  const sidebarReferAFriend = useTypedFeatureIsOn("sidebar-refer-a-friend");

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const USER_MENU_ITEMS: MenuItemsGroup = useMemo(() => {
    const label =
      userInfo.given_name && userInfo.family_name
        ? `${userInfo.given_name} ${userInfo.family_name}`
        : userInfo.email ?? "Guest";

    const menuItems = [
      {
        key: "account",
        label: "Manage Account",
        icon: <MdOutlineAccountCircle />,
        onClick: () => navigate("/manage-account"),
      },
      {
        key: "settings",
        label: "Settings",
        icon: <MdSettings />,
        onClick: () => setIsSettingsOpen(true),
      },
      ...(realm?.isMarky
        ? [
            ...(showReferAFriendButton && !sidebarReferAFriend
              ? [
                  {
                    label: (
                      <ReferralButton
                        type="text"
                        className="!p-0 !border-0 !flex !gap-2 !text-antd-colorText !h-[unset] hover:!bg-[unset]"
                      />
                    ),
                    key: "refer",
                  },
                ]
              : []),
            {
              key: "affiliate",
              label: "Become an Affiliate",
              icon: <MdOutlineAttachMoney />,
              onClick: () => openInNewTab("https://mymarky.firstpromoter.com/"),
            },
            ...(agentChatEnabled
              ? [
                  {
                    key: "chat",
                    label: "AI Chat (Beta)",

                    icon: <MdOutlineSmartToy />,
                    onClick: () => {
                      const params = new URLSearchParams({
                        businessId: currentBusiness.id,
                        token: token || "",
                      });
                      openInNewTab(
                        `https://d3b8oybmyy54c3.cloudfront.net/?${params.toString()}`
                      );
                    },
                  },
                ]
              : []),
          ]
        : []),
      {
        key: "logout",
        label: "Logout",
        icon: <MdLogout />,
        onClick: () => navigate("/logout"),
      },
    ];

    return {
      icon: <AvatarInitials user={userInfo} size={20} />,
      topDivider: true,
      items: menuItems,
      label,
      testId: "user-sidebar-menu",
      order: 1,
    };
  }, [
    userInfo,
    isAgencyOwner,
    showReferAFriendButton,
    sidebarReferAFriend,
    agentChatEnabled,
    currentBusiness.id,
    token,
  ]);

  const menus = useMemo(() => {
    const menuArray = [USER_MENU_ITEMS];

    realm.isMarky && menuArray.unshift(HELP_MENU_ITEMS);

    return menuArray;
  }, [realm.isMarky, USER_MENU_ITEMS]);

  return (
    <div className="mt-auto pt-2 space-y-4">
      <SidebarWidgetContainer />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col lg:px-2 mb-2 gap-3">
          <ProductTourButton />
          <UpgradePlanButton />
        </div>
        {showReferAFriendButton && sidebarReferAFriend && (
          <ReferralButton className="order-1" />
        )}
        {menus.map(
          ({ icon, label, topDivider, items, order, testId }, index) => (
            <div
              key={index}
              style={{ order }}
              className="[&_button.ant-dropdown-open]:text-antd-colorText"
            >
              {topDivider && (
                <div className="px-1">
                  <Divider className="!mt-0 !mb-2" />
                </div>
              )}
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="topLeft"
                arrow
                overlayClassName="[&_svg]:size-4"
              >
                <SidebarTextButton className="w-full" icon={icon}>
                  <span
                    data-testid={testId}
                    className="overflow-hidden text-ellipsis"
                  >
                    {label}
                  </span>
                </SidebarTextButton>
              </Dropdown>
            </div>
          )
        )}

        {isAgencyOwner && currentBusiness.id && (
          <Link to="/dashboard">
            <SidebarTextButton
              className="w-full"
              icon={<MdArrowBack size={20} />}
            >
              Back to Agency
            </SidebarTextButton>
          </Link>
        )}

        <Settings
          close={() => setIsSettingsOpen(false)}
          isOpen={isSettingsOpen}
        />
      </div>
    </div>
  );
};

export default SidebarBottomMenu;
