import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureValue } from "config/Growthbook/growthbookUtils";
import { FADE_IN_ANIMATION } from "fixtures/motionAnimations";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import useOnboardingTracking from "hooks/useOnboardingTracking";
import { useEffect, useMemo, useState } from "react";
import { WIDGETS } from "./widgetFixtures";

const WIDGET_MODAL_ANIMATION: MotionProps = {
  initial: { transform: "translateY(-10px) translateX(-50%)", opacity: 0 },
  animate: { transform: "translateY(0px) translateX(-50%)", opacity: 1 },
  exit: { transform: "translateY(-10px) translateX(-50%)", opacity: 0 },
};

const SidebarWidgetContainer = ({
  isMobileLogo,
}: {
  isMobileLogo?: boolean;
}) => {
  const {
    overrideMobileHeaderState: [, setOverrideMobileHeader],
  } = useAppContext();
  const [showMobileCard, setShowMobileCard] = useState(false);

  const { onboardingTrackerEnabled } = useOnboardingTracking();
  const postTankEnabled = useTypedFeatureValue("post-tank", "OFF") !== "OFF";

  const Widget = useMemo(
    () =>
      onboardingTrackerEnabled
        ? WIDGETS.onboardingTracker
        : postTankEnabled
          ? WIDGETS.postTank
          : null,
    [onboardingTrackerEnabled, postTankEnabled]
  );

  useEffect(() => {
    if (Widget && isMobileLogo) {
      setOverrideMobileHeader(true);
    }

    return () => {
      isMobileLogo && setOverrideMobileHeader(false);
    };
  }, [Widget, isMobileLogo]);

  const mobileClassNames = "flex flex-row-reverse items-center";

  return (
    Widget && (
      <>
        {isMobileLogo && (
          <Widget
            className={classNames(mobileClassNames, "gap-2")}
            isMobileLogo
            onClick={() => setShowMobileCard(true)}
          />
        )}

        <AnimatePresence>
          {showMobileCard && (
            <motion.div
              key="mask"
              onClick={() => setShowMobileCard(false)}
              {...FADE_IN_ANIMATION}
              className="bg-antd-colorBgMask fixed h-dvh w-dvw top-0 left-0 z-antd-popup"
            />
          )}

          {(showMobileCard || !isMobileLogo) && (
            <motion.div
              key="card"
              {...(isMobileLogo && WIDGET_MODAL_ANIMATION)}
              className={
                showMobileCard
                  ? "fixed top-4 left-1/2 z-antd-popup"
                  : "max-lg:hidden mx-2"
              }
            >
              <Widget
                className={classNames(
                  mobileClassNames,
                  "gap-4 lg:flex-col lg:gap-2.5 p-4 rounded-lg bg-antd-colorBgContainer text-sm shadow-sm border border-antd-colorBorderSecondary max-lg:w-80 max-w-full"
                )}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  );
};

export default SidebarWidgetContainer;
