import { useCallback, useMemo, useState } from "react";

import { Button, Dropdown, message } from "antd";
import { deleteUser } from "api/user";

import EditUserDetails from "../EditUserDetails";
import DeleteConfirmationModal from "./DeleteAccountModal";

import { FaEllipsisVertical } from "react-icons/fa6";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import UpdatePassword from "../UpdatePassword";

const UserMenu = ({ logout }: { logout: () => void }) => {
  const user = useAppSelector(selectUserInfo);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] =
    useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const items = useMemo(() => {
    const menuItems = [
      {
        key: 2,
        label: "Delete Account",
      },
    ];

    if (user.email) {
      menuItems.unshift({
        key: 3,
        label: "Update Password",
      });
      menuItems.unshift({
        key: 4,
        label: "Edit Account Details",
      });
    }

    return menuItems;
  }, [user, isLoading]);

  const handleClick = useCallback(({ key }: { key: string }) => {
    switch (key) {
      case "2":
        setIsDeleteUserModalOpen(true);
        break;
      case "3":
        setIsUpdatePasswordModalOpen(true);
        break;
      case "4":
        setIsUpdateUserModalOpen(true);
        break;
    }
  }, []);

  const deleteUserAccount = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteUser();
      setIsDeleteUserModalOpen(false);
      message.success("Account deleted successfully.");
      logout();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      <Dropdown
        menu={{ items, onClick: handleClick }}
        placement="bottomRight"
        trigger={["click"]}
      >
        <Button
          data-testid="user-menu-dropdown"
          aria-label="User menu"
          size="small"
          type="text"
          icon={<FaEllipsisVertical className="cursor-pointer" />}
        />
      </Dropdown>
      <DeleteConfirmationModal
        close={() => setIsDeleteUserModalOpen(false)}
        successCallback={deleteUserAccount}
        isOpen={isDeleteUserModalOpen}
        isLoading={isLoading}
      />
      {isUpdatePasswordModalOpen && (
        <UpdatePassword
          close={() => setIsUpdatePasswordModalOpen(false)}
          isOpen={isUpdatePasswordModalOpen}
        />
      )}
      {isUpdateUserModalOpen && (
        <EditUserDetails
          close={() => setIsUpdateUserModalOpen(false)}
          isOpen={isUpdateUserModalOpen}
        />
      )}
    </>
  );
};

export default UserMenu;
