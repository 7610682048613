import { Button, Checkbox, message, Radio } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { updateUserDetails } from "store/user/userActions";
import { selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { IteratePartFn } from "../businessOnboardingConstants";
import OnboardingModalTemplate, {
  OnboardingModalTemplateProps,
} from "./OnboardingModalTemplate";

const REFERRAL_OPTIONS = [
  "Search Engine (Google, Bing, etc.)",
  "Social Media",
  "Recommended by a Friend",
  "Email Newsletter",
  "Blog or Article",
  "Other",
];

const NewUserPreferences = ({
  parentSubmitting,
  uploadSelectedImages,
  iteratePart,
  ...modalProps
}: {
  parentSubmitting: boolean;
  iteratePart: IteratePartFn;
  uploadSelectedImages: () => void;
} & Pick<OnboardingModalTemplateProps, "imageSrc" | "imageBg">) => {
  const dispatch = useAppDispatch();
  const { isAnonymous } = useAppSelector(selectUserInfo);
  const [preferences, setPreferences] = useState({
    referralMethod: "",
    subscribeEmails: true,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted && !parentSubmitting) {
      iteratePart("next");
    }
  }, [isSubmitted, parentSubmitting]);

  const submitData = () => {
    // Don't block user from entering app while preferences submission is pending
    setIsSubmitted(true);
    try {
      uploadSelectedImages();
      dispatch(
        updateUserDetails({
          discovery_method: preferences.referralMethod,
        })
      );
      trackEvent("tutorial_complete");
    } catch (error) {
      console.error(error);
      message.error("Unable to save email preference.");
    }
  };

  return (
    <OnboardingModalTemplate
      {...modalProps}
      open
      hideTransitions
      header="One last thing!"
      footer={
        <div className="flex gap-2">
          <Button onClick={() => iteratePart("previous")}>Back</Button>
          <Button
            data-testid="finish-onboarding"
            type="primary"
            loading={isSubmitted && parentSubmitting}
            onClick={submitData}
            className="flex-grow"
          >
            Finish and Generate My Posts
          </Button>
        </div>
      }
    >
      <FormItem label="How did you hear about us?" layout="vertical">
        <Radio.Group
          className="flex flex-col gap-1"
          value={preferences.referralMethod}
          onChange={(e) =>
            setPreferences({ ...preferences, referralMethod: e.target.value })
          }
        >
          {REFERRAL_OPTIONS.map((option) => (
            <Radio value={option} key={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </FormItem>
      {!isAnonymous && (
        <Checkbox
          checked={preferences.subscribeEmails}
          onChange={({ target }) =>
            setPreferences({ ...preferences, subscribeEmails: target.checked })
          }
        >
          <span className="font-normal">
            I would like to receive useful tips and marketing emails from Marky
          </span>
        </Checkbox>
      )}
    </OnboardingModalTemplate>
  );
};

export default NewUserPreferences;
