import { Menu } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { ONBOARDING_TOUR_STEPS } from "components/GlobalModals/OnboardingTour/onboardingTourSteps";
import useAppContext from "config/AppContext/useAppContext";
import { AppFeatures } from "config/Growthbook/feature-flags";
import growthbook, {
  useTypedFeatureIsOn,
} from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { AGENCY_ROUTES } from "fixtures/constants";
import { isMobileScreenSize } from "fixtures/globalConstants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectPostStore, setAnimateTab } from "store/posts/postSlice";
import { currentBusinessGetter } from "store/user/userSlice";
import {
  AGENCY_SIDEBAR_SECTIONS,
  BUSINESS_SIDEBAR_SECTIONS,
  SECTION_CHILDREN_MAPPING,
  SIDEBAR_ITEM_CONFIG,
  SidebarItemKey,
  SidebarSectionKey,
} from "./sidebarFixtures";

const getRouteFromPath = (pathname: string) =>
  pathname.split("/")[1] as SidebarSectionKey;

const SidebarNavItems = () => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const dispatch = useAppDispatch();
  const postStore = useAppSelector(selectPostStore);
  const { id: currentBusinessId } = useAppSelector(currentBusinessGetter);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    realm,
    sidebarCollapsedState: [isCollapsed],
  } = useAppContext();

  const { hasPermission } = useUserPermissions();
  const { onboardingTourStep, isAgencyMember } = useAppSelector(
    (state) => state.user
  );

  const readBusiness = hasPermission("business_details_read");

  const [openSections, setOpenSections] = useState<SidebarSectionKey[]>([]);

  const selectedKey = useMemo(
    () => getRouteFromPath(pathname) as SidebarItemKey,
    [pathname]
  );

  useEffect(() => {
    !isCollapsed && syncOpenSections(selectedKey);
  }, [isCollapsed]);

  useEffect(() => {
    setOpenSections([]);
  }, [currentBusinessId]);

  useEffect(() => {
    if (onboardingTourStep !== null) {
      // Check open section for next step so that it's open in time
      const openSection =
        ONBOARDING_TOUR_STEPS[onboardingTourStep + 1]?.sidebarSection;
      openSection && setOpenSections([openSection]);
    } else {
      syncOpenSections(selectedKey);
    }
  }, [onboardingTourStep]);

  const sidebarSections: readonly SidebarSectionKey[] = useMemo(
    () =>
      AGENCY_ROUTES.includes(pathname) && (readBusiness || isAgencyMember)
        ? AGENCY_SIDEBAR_SECTIONS
        : BUSINESS_SIDEBAR_SECTIONS,
    [pathname, readBusiness]
  );

  // TODO: Move to hook? Used both here and in PostScreenSelector. Easier to adjust after post-calendar
  // flag enabled
  let animateTimeout: ReturnType<typeof setTimeout>;
  useEffect(() => {
    clearTimeout(animateTimeout);
    animateTimeout = setTimeout(() => {
      dispatch(setAnimateTab(null));
    }, 1000);
  }, [postStore.animateTab]);

  const permissionsSatisfied = (key: SidebarItemKey) => {
    const { needsSomePermission, mustBeAgencyMember } =
      SIDEBAR_ITEM_CONFIG[key];
    return (
      (!mustBeAgencyMember || isAgencyMember) &&
      (!needsSomePermission ||
        needsSomePermission.some((permission) => hasPermission(permission)))
    );
  };

  const selectSidebarItem = useCallback(
    ({
      sectionKey,
      sectionChildrenKeys,
      navigateToKey,
    }: Either<
      {
        sectionKey: SidebarSectionKey;
        sectionChildrenKeys?: SidebarItemKey[];
      },
      { navigateToKey: SidebarItemKey }
    >) => {
      if (sectionKey) {
        const toggleOpen = !openSections.includes(sectionKey);

        setOpenSections(
          toggleOpen
            ? [sectionKey]
            : openSections.filter((key) => key !== sectionKey)
        );

        if (
          !isMobileScreenSize() &&
          toggleOpen &&
          sectionChildrenKeys?.length
        ) {
          const childIsAlreadySelected = sectionChildrenKeys?.some((key) =>
            selectedKey.includes(key)
          );
          !childIsAlreadySelected && navigate(`/${sectionChildrenKeys[0]}`);
        }
      } else {
        const navigateConfig = SIDEBAR_ITEM_CONFIG[navigateToKey];
        setOpenSections(
          navigateConfig.parentKey ? [navigateConfig.parentKey] : []
        );
        navigate(navigateConfig.path);
      }
    },
    [openSections, selectedKey]
  );

  const sidebarNavItems = useMemo(() => {
    const enableItem = (behindFlag?: keyof AppFeatures) =>
      !behindFlag || growthbook.isOn(behindFlag);

    const sections = sidebarSections.reduce(
      (prev, sectionKey) => {
        const {
          behindFlag,
          needsSomePermission: _omitPerms,
          mustBeAgencyMember: _omitAgency,
          ...sectionConfig
        } = SIDEBAR_ITEM_CONFIG[sectionKey];

        if (enableItem(behindFlag) && permissionsSatisfied(sectionKey)) {
          let sectionChildrenKeys = SECTION_CHILDREN_MAPPING[sectionKey];
          if (sectionKey === "business") {
            sectionChildrenKeys = sectionChildrenKeys?.filter((key) =>
              permissionsSatisfied(key)
            );
          }

          const sectionChildrenConfigs = sectionChildrenKeys?.flatMap(
            (childKey) => {
              const { label, icon, behindFlag } = SIDEBAR_ITEM_CONFIG[childKey];
              return enableItem(behindFlag)
                ? { key: childKey, label, icon }
                : [];
            }
          );

          prev.push({
            key: sectionKey,
            ...sectionConfig,
            children: sectionChildrenConfigs?.length
              ? sectionChildrenConfigs
              : undefined,
            sectionChildrenKeys,
          });
        }

        return prev;
      },
      [] as (ItemType & {
        key: SidebarSectionKey;
        sectionChildrenKeys?: SidebarItemKey[];
      })[]
    );

    return sections.map(({ sectionChildrenKeys, ...item }) => ({
      ...item,
      onTitleClick: () =>
        selectSidebarItem({
          sectionKey: item.key,
          sectionChildrenKeys,
        }),
    }));
  }, [
    sidebarSections,
    isAgencyMember,
    realm.isMarky,
    selectSidebarItem,
    postCalendar,
    hasPermission,
  ]);

  const syncOpenSections = (openKey: SidebarItemKey) => {
    const itemConfig = SIDEBAR_ITEM_CONFIG[openKey];

    if (
      itemConfig?.parentKey &&
      sidebarSections.includes(itemConfig.parentKey)
    ) {
      setOpenSections([itemConfig.parentKey]);
    }
  };

  return (
    <Menu
      selectedKeys={[selectedKey]}
      onClick={({ key }) =>
        selectSidebarItem({ navigateToKey: key as SidebarItemKey })
      }
      openKeys={openSections}
      items={sidebarNavItems}
      className="!bg-transparent overflow-auto"
      mode="inline"
    />
  );
};

export default SidebarNavItems;
