export type BusinessOnboardingMilestoneData = {
  value: boolean;
  updatedTimestamp: number;
};

export type BusinessOnboardingMilestone =
  | "hasConnectedIntegration"
  | "hasEditedPost"
  | "hasEditedThemes"
  | "hasEditedTopics"
  | "hasEditedBusinessDetails"
  | "hasScheduledOnePost"
  | "hasScheduledTenPosts"
  | "hasPublishedOnePost"
  | "hasPublishedTenPosts";

export type BusinessSumTrackingKey =
  | "lifetimeScheduledPosts"
  | "lifetimePublishedPosts";

export type OnboardingMilestonesDict = {
  [key in BusinessOnboardingMilestone]?: BusinessOnboardingMilestoneData;
};

export type OnboardingTracking = {
  onboardingMilestones?: OnboardingMilestonesDict;
  showMilestoneTracker?: boolean;
} & { [key in BusinessSumTrackingKey]?: number };

export type OnboardingMilestoneLabel = {
  key: BusinessOnboardingMilestone | "id";
  label: string;
};

export type CombinedMilestoneData = OnboardingMilestoneLabel &
  Partial<BusinessOnboardingMilestoneData>;

export type SummableMilestoneConfig = {
  sumKey: BusinessSumTrackingKey;
  milestoneKey: BusinessOnboardingMilestone;
  needsSum: number;
};

export const SUMMABLE_MILESTONES_ITERATABLE: SummableMilestoneConfig[] = [
  {
    sumKey: "lifetimeScheduledPosts",
    milestoneKey: "hasScheduledOnePost",
    needsSum: 1,
  },
  {
    sumKey: "lifetimeScheduledPosts",
    milestoneKey: "hasScheduledTenPosts",
    needsSum: 10,
  },
  {
    sumKey: "lifetimePublishedPosts",
    milestoneKey: "hasPublishedOnePost",
    needsSum: 1,
  },
  {
    sumKey: "lifetimePublishedPosts",
    milestoneKey: "hasPublishedTenPosts",
    needsSum: 10,
  },
] as const;

export const ONBOARDING_MILESTONE_LABELS: OnboardingMilestoneLabel[] = [
  {
    key: "id",
    label: "Create my business",
  },
  {
    key: "hasConnectedIntegration",
    label: "Connect a social",
  },
  {
    key: "hasEditedPost",
    label: "Edit a post",
  },
  {
    key: "hasScheduledOnePost",
    label: "Schedule a post",
  },
  {
    key: "hasPublishedOnePost",
    label: "Publish a post",
  },
  {
    key: "hasEditedThemes",
    label: "Customize Themes",
  },
  {
    key: "hasEditedTopics",
    label: "Customize Topics",
  },
  {
    key: "hasEditedBusinessDetails",
    label: "Edit Business Details",
  },
  {
    key: "hasScheduledTenPosts",
    label: "Schedule 10 posts",
  },
  {
    key: "hasPublishedTenPosts",
    label: "Publish 10 posts",
  },
];
