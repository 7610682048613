import { Button, Collapse } from "antd";
import axios from "axios";
import { UseStateReturn } from "fixtures/globalConstants";
import useMissingImages from "hooks/useMissingImages";
import { range, uniqBy } from "lodash";
import ImageGallery from "pages/ImageLibrary/ImageGallery";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { imageSearchTermsGetter } from "store/user/userSlice";

type ImageLibraryConfirmationProps = {
  setSelectedImages: UseStateReturn<string[]>[1];
  selectedImages: string[];
  labelClassName?: string;
  galleryClassName?: string;
  hideInitialLoader?: boolean;
  scrapedImages?: string[];
};

const ImageLibraryConfirmation = ({
  setSelectedImages,
  selectedImages,
  labelClassName,
  galleryClassName,
  hideInitialLoader,
  scrapedImages: scrapedImagesProp,
}: ImageLibraryConfirmationProps) => {
  const imageSearchTerms = useAppSelector(imageSearchTermsGetter) as string[];

  const { state } = useLocation();
  const [images, setImages] = useState([] as { urls: { regular: string } }[]);

  const scrapedImages: string[] =
    scrapedImagesProp || state?.scrapedImagesFromWebsite || [];

  const isAllScrapedSelected = scrapedImages.every((image) =>
    selectedImages.includes(image)
  );

  const handleSelectAllScraped = () => {
    if (isAllScrapedSelected) {
      setSelectedImages([]);
    } else {
      setSelectedImages(scrapedImages);
    }
  };

  const fetchImages = async () => {
    try {
      const promises = imageSearchTerms.map((keyword) => {
        return axios.get("https://api.unsplash.com/search/photos", {
          params: {
            client_id: import.meta.env.VITE_UNSPLASH_CLIENT_ACCESS_KEY,
            per_page: 20,
            query: keyword,
          },
        });
      });

      const responses = await Promise.all(promises);

      setImages(
        uniqBy(responses.map((response) => response.data.results).flat(), "id")
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (imageSearchTerms.length) {
      fetchImages();
    }
  }, [imageSearchTerms]);

  const { MissingImagesMessage, incrementMissing } = useMissingImages();

  if (!images.length && !hideInitialLoader) {
    return (
      <div>
        {range(1, 10).map((item) => (
          <Skeleton width={104} height={104} borderRadius={8} key={item} />
        ))}
      </div>
    );
  }

  return (
    <div>
      {scrapedImages.length > 0 && (
        <Collapse
          className="[&_.ant-collapse-header]:!px-0 [&_.ant-collapse-content-box]:!px-0"
          ghost
          items={[
            {
              key: "images-confirmation",
              label: (
                <span data-testid="expand-scraped-images">
                  {pluralize("Image", scrapedImages.length, true)} found on
                  website
                </span>
              ),
              children: (
                <div>
                  <div
                    className={`flex items-center justify-between ${labelClassName}`}
                  >
                    <div>
                      {`Importing ${pluralize("image", selectedImages.length, true)}`}
                    </div>
                    <Button
                      onClick={handleSelectAllScraped}
                      className="px-0"
                      type="link"
                    >
                      {isAllScrapedSelected ? "Deselect" : "Select"} All
                    </Button>
                  </div>
                  <ImageGallery
                    className={
                      galleryClassName ?? "max-h-[400px] overflow-auto"
                    }
                    images={scrapedImages.map((image) => ({
                      id: image,
                      file: { url: image },
                      isUploading: false,
                    }))}
                    selectedImages={selectedImages.map((url) => ({
                      id: url,
                      file: { url },
                      isUploading: false,
                    }))}
                    setSelectedImages={(images) =>
                      setSelectedImages(images.map(({ file: { url } }) => url))
                    }
                    onError={incrementMissing}
                  />
                  <MissingImagesMessage />
                </div>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

export default ImageLibraryConfirmation;
