import { Button, InputNumber, message } from "antd";
import { changePlan } from "api/stripe";
import ModalWithBorders from "components/ModalWithBorders";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails } from "store/user/userActions";
import { selectUserInfo } from "store/user/userSlice";

const AddBusinessModal = ({ close }: { close: () => void }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { subscription } = useAppSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [businessCount, setBusinessCount] = useState(1);

  const isYearly = subscription?.plan?.includes("yearly");

  const perBusinessCost = isYearly ? 12.5 : 15;

  const subtitle = useMemo(
    () =>
      `Want to increase your business limit? You can add additional businesses to your account at ${perBusinessCost.toFixed(
        2
      )}/month each.`,
    [perBusinessCost]
  );

  const handleContinue = useCallback(async () => {
    setIsLoading(true);
    try {
      await changePlan({
        extra_businesses: (subscription?.extra_businesses ?? 0) + businessCount,
      });
      await dispatch(getUserDetails());
      close();
      navigate("/business-metadata?isNewBusiness=true");
      message.success("Successfully updated the business limit.");
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    }
    setIsLoading(false);
  }, [businessCount, subscription]);

  return (
    <ModalWithBorders
      className="add-business-modal"
      maskClosable={!isLoading}
      closable={!isLoading}
      onCancel={close}
      destroyOnClose
      footer={
        <>
          <Button onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            disabled={!businessCount}
            onClick={handleContinue}
            loading={isLoading}
            type="primary"
          >
            Continue
          </Button>
        </>
      }
      title="Add More Businesses"
      open
      width={670}
      centered
    >
      <div className="flex flex-col gap-4">
        <p className="font-normal text-sm text-antd-colorTextSecondary">
          {subtitle}
        </p>
        <div className="flex items-center gap-4">
          <label>
            How many more businesses would you like to add to Marky?
          </label>
          <InputNumber
            onChange={(value) => setBusinessCount(value as number)}
            value={businessCount}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            min={1}
          />
        </div>

        <div className="font-normal text-sm text-antd-colorTextSecondary relative max-w-[600px]">
          Based on your input, your total additional cost for Marky will be{" "}
          <strong>${(businessCount * perBusinessCost).toFixed(2)}/month</strong>
          {isYearly
            ? ` ($${(perBusinessCost * businessCount * 12).toFixed(
                2
              )} billed annually)`
            : ""}
          . Ready to proceed?
        </div>
      </div>
    </ModalWithBorders>
  );
};

export default AddBusinessModal;
