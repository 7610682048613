import {
  BusinessOnboardingMilestoneData,
  CombinedMilestoneData,
  ONBOARDING_MILESTONE_LABELS,
  OnboardingMilestonesDict,
} from "./onboardingMilestoneFixtures";

export const reduceMilestonesByCompletionStatus = (
  onboardingMilestones?: OnboardingMilestonesDict
) => {
  const { completed, incomplete } = ONBOARDING_MILESTONE_LABELS.reduce<{
    completed: Required<CombinedMilestoneData>[];
    incomplete: CombinedMilestoneData[];
  }>(
    (prev, milestone) => {
      const milestoneData: BusinessOnboardingMilestoneData | undefined =
        milestone.key === "id"
          ? { value: true, updatedTimestamp: -1 }
          : onboardingMilestones?.[milestone.key];

      if (milestoneData?.value) {
        prev.completed.push({ ...milestone, ...milestoneData });
      } else {
        prev.incomplete.push(milestone);
      }
      return prev;
    },
    { completed: [], incomplete: [] }
  );

  return {
    completed,
    incomplete,
    percentComplete:
      100 * (completed.length / ONBOARDING_MILESTONE_LABELS.length),
  };
};
