import { Button, Modal, Switch } from "antd";
import { Permissions } from "api/config/chalice-api";
import useAppContext from "config/AppContext/useAppContext";
import { ENV_NAME } from "config/envVars";
import { PERMISSIONS_PRESET } from "pages/Teams/components/EditMemberPermissions/memberPermissionConstants";
import { createContext, Fragment, ReactNode, useMemo, useState } from "react";
import { MdSettings } from "react-icons/md";
import { useLocalStorage } from "react-use";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import {
  DEFAULT_PERMISSIONS,
  DEFAULT_USER_PERMISSIONS_CONTEXT,
  OVERRIDE_PERMISSIONS_KEY,
  PERMISSION_STRINGS,
  PermissionString,
  UserPermissionsContextType,
} from "./userPermissionsConstants";

export const UserPermissionsContext = createContext(
  DEFAULT_USER_PERMISSIONS_CONTEXT
);

const UserPermissionsProvider = ({ children }: { children: ReactNode }) => {
  const {
    previewModeState: [isPreviewMode],
  } = useAppContext();
  const [
    permissionOverrides,
    setPermissionOverrides,
    clearPermissionOverrides,
  ] = useLocalStorage<Permissions>(OVERRIDE_PERMISSIONS_KEY);
  const [showOverridesModal, setShowOverridesModal] = useState(false);

  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { isAgencyOwner, userInfo } = useAppSelector((state) => state.user);

  // TODO: Only business members have assigned permissions for the business, owners
  // are assumed to have all permissions.
  const { isCurrentBusinessOwner, businessMemberPermissions, userPermissions } =
    useMemo(() => {
      // TODO: This check doesn't make sense if there's no active business, such as when
      // viewing the agency dashboard.
      const isCurrentBusinessOwner =
        userInfo.id === currentBusiness.user_id ||
        (!currentBusiness?.id && isAgencyOwner);

      const businessMemberPermissions = {
        ...DEFAULT_PERMISSIONS(),
        ...currentBusiness.members?.find((member) => member.id === userInfo.id)
          ?.permissions,
        ...permissionOverrides,
      };

      const ownerPermissions = {
        ...DEFAULT_PERMISSIONS(true),
        ...permissionOverrides,
      };

      return {
        isCurrentBusinessOwner,
        businessMemberPermissions,
        userPermissions: isCurrentBusinessOwner
          ? ownerPermissions
          : businessMemberPermissions,
      };
    }, [currentBusiness, isAgencyOwner, userInfo, permissionOverrides]);

  const permissionsObject = (ownerOverride = true) =>
    // TODO: Leverage the `setPermissionsOverrides` function when navigating to the WL preview as a business owner.
    // Remove this override.
    isPreviewMode && userInfo.is_agency
      ? PERMISSIONS_PRESET.reviewer.permissions
      : ownerOverride
        ? userPermissions
        : businessMemberPermissions;

  const hasPermission: UserPermissionsContextType["hasPermission"] = (
    checkPerm,
    ownerOverride = true
  ) => permissionsObject(ownerOverride)[checkPerm];

  const hasSomePermissions: UserPermissionsContextType["hasSomePermissions"] = (
    checkPerms: PermissionString[] | readonly PermissionString[],
    ownerOverride = true
  ) => {
    const checkObject = permissionsObject(ownerOverride);
    return checkPerms.some((perm) => checkObject[perm]);
  };

  return (
    <UserPermissionsContext.Provider
      value={{
        isCurrentBusinessOwner,
        userPermissions,
        hasPermission,
        hasSomePermissions,
        setPermissionOverrides,
        clearPermissionOverrides,
      }}
    >
      {children}

      {/* TODO: This will probably need to change once we add preview mode. Adding like this for now so that it's easy to test permissions during local dev, or in prod if you know the local storage key.

      Permissions are already hackable by editing API return in network requests, BE must validate all permissions.
      */}
      {(ENV_NAME === "DEV" ||
        (permissionOverrides && isCurrentBusinessOwner)) && (
        <>
          <MdSettings
            className="fixed top-0 right-0 cursor-pointer opacity-0 hover:opacity-100 transition-opacity"
            onClick={() => setShowOverridesModal(true)}
          />
          <Modal
            open={showOverridesModal}
            title="Override permissions"
            onCancel={() => setShowOverridesModal(false)}
            onClose={() => setShowOverridesModal(false)}
            footer={
              <div className="flex gap-4">
                <Button
                  type="primary"
                  onClick={() => {
                    clearPermissionOverrides();
                    setShowOverridesModal(false);
                  }}
                >
                  Clear overrides
                </Button>
              </div>
            }
          >
            <div className="grid grid-cols-2 gap-2 max-h-[60vh] overflow-auto">
              {PERMISSION_STRINGS.map((permission) => (
                <Fragment key={permission}>
                  <span className="text-xs">{permission}</span>
                  <Switch
                    size="small"
                    className="max-w-fit"
                    value={userPermissions[permission]}
                    onChange={(checked) =>
                      setPermissionOverrides({
                        ...permissionOverrides,
                        [permission]: checked,
                      })
                    }
                  />
                </Fragment>
              ))}
            </div>
          </Modal>
        </>
      )}
    </UserPermissionsContext.Provider>
  );
};

export default UserPermissionsProvider;
