import { Button, Progress } from "antd";
import classNames from "classnames";
import InfoTooltip from "components/Common/InfoTooltip";
import { isMobileScreenSize } from "fixtures/globalConstants";
import {
  FADE_IN_ANIMATION,
  SLIDE_UP_ANIMATION,
} from "fixtures/motionAnimations";
import { AnimatePresence, motion } from "framer-motion";
import useOnboardingTracking from "hooks/useOnboardingTracking";
import { useMemo } from "react";
import {
  MdOutlineClose,
  MdRadioButtonUnchecked,
  MdTaskAlt,
} from "react-icons/md";
import { useAppSelector } from "store";
import { currentBusinessOnboardingTracking } from "store/user/userSlice";
import { WidgetComponent } from "../widgetFixtures";
import { reduceMilestonesByCompletionStatus } from "./onboardingMilestoneUtils";

const OnboardingMilestoneTracker: WidgetComponent = ({
  isMobileLogo,
  onClick: onClickProp,
  ...props
}) => {
  const onboardingTracking = useAppSelector(currentBusinessOnboardingTracking);
  const { updateOnboardingTracking } = useOnboardingTracking();

  const { showMilestones, percentComplete } = useMemo(() => {
    const { completed, incomplete, percentComplete } =
      reduceMilestonesByCompletionStatus(
        onboardingTracking.onboardingMilestones
      );

    if (isMobileLogo) {
      return {
        showMilestones: incomplete.length
          ? [{ ...incomplete[0], value: false }]
          : [],
        percentComplete,
      };
    }

    completed.sort((a, b) => a.updatedTimestamp - b.updatedTimestamp);

    // Show max of 3 items:
    // - The most recently completed item
    // - Next incompleted items
    const showMilestones = [
      ...completed.slice(completed.length - 1),
      ...incomplete,
    ].slice(0, 3);

    return {
      showMilestones,
      percentComplete,
    };
  }, [onboardingTracking, isMobileLogo]);

  const isMobileCard = isMobileScreenSize() && !isMobileLogo;
  const isComplete = percentComplete === 100;
  const dismissTracker = () =>
    updateOnboardingTracking({ showMilestoneTracker: false });

  return (
    <div
      id="onboarding-milestone-tracker"
      {...props}
      onClick={isComplete ? dismissTracker : onClickProp}
    >
      <AnimatePresence>
        {isComplete ? (
          <motion.div {...FADE_IN_ANIMATION}>
            <div
              className={classNames(
                "font-semibold flex justify-between items-center mb-1",
                isMobileLogo && "text-xs"
              )}
            >
              Congratulations!
              {!isMobileLogo && (
                <Button
                  type="text"
                  size="small"
                  className="-mt-2.5 -mr-2.5"
                  icon={
                    <MdOutlineClose size={18} className="fill-antd-colorIcon" />
                  }
                  onClick={dismissTracker}
                />
              )}
            </div>

            <p className="text-antd-colorTextSecondary text-xs flex flex-col gap-2">
              You're on your way to a rockstar social media presence.
            </p>
          </motion.div>
        ) : (
          <div className="flex flex-grow w-full justify-between items-start">
            <div
              className={classNames(
                isMobileLogo
                  ? "text-xs text-antd-colorTextSecondary"
                  : "space-y-1"
              )}
            >
              {isMobileLogo && "Next step:"}
              {showMilestones.map(({ key, label, value }) => (
                <motion.div
                  key={key}
                  {...(!isMobileCard && SLIDE_UP_ANIMATION)}
                  className={classNames("flex items-center gap-2 py-0.5", {
                    "text-antd-colorTextSecondary": !value,
                  })}
                >
                  {!isMobileLogo &&
                    (value ? (
                      <MdTaskAlt className="fill-antd-colorSuccess" size={18} />
                    ) : (
                      <MdRadioButtonUnchecked size={18} />
                    ))}
                  {label}
                </motion.div>
              ))}
            </div>
            {!isMobileLogo && (
              <InfoTooltip title="Complete all onboarding steps to jumpstart your social media success!" />
            )}
          </div>
        )}
      </AnimatePresence>
      <Progress
        showInfo={isMobileCard}
        size={isMobileLogo ? 30 : isMobileCard ? 64 : undefined}
        type={isMobileScreenSize() ? "circle" : "line"}
        percent={percentComplete}
        className="[&_.ant-progress-circle-path]:!stroke-antd-colorSuccess [&_.ant-progress-bg]:bg-antd-colorSuccess [&_*]:!text-xs"
      />
    </div>
  );
};

export default OnboardingMilestoneTracker;
